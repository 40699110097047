<template>
	<div class="tab-locatif" id='report-locatif'>
		<div class="tab-content" v-observe-visibility="onVisibilityChange" >
			<Fieldset :legend="$t('marche_du_locatif')" :toggleable="true" class="surface-ground">
				<div class="mb-2" style="height: 30px; vertical-align: middle">
					Zonage Pinel : <Chip style="font-size: 0.7rem;" v-if="app.adr_init.zonage" :label="'Zone ' + app.adr_init.zonage" class="mr-2" :class="{ zabis: app.adr_init.zonage=='Abis', za: app.adr_init.zonage=='A', zb1: app.adr_init.zonage=='B1', zb2: app.adr_init.zonage=='B2', zc: app.adr_init.zonage=='C' }" />
				</div>
				<div style="display: flex;">
					<!--
					<div class="part" style="width:32%;margin-bottom:5px !important;" v-if="app.adr_init.type !='city'">
						<div class="py-1" style="padding-right:0px !important">
							<div class="grid">
								<div class="col-12">
									<div class="surface-card shadow-2 p-3 border-round">
										<div class="flex justify-content-between mb-3">
											<div>
												<span class="block text-900 font-medium text-xl mb-3" style="padding-bottom: 1rem;">{{ $t('marche_du_locatif') }} {{app.locatif.loyer_yanport_geo.irisIds.label}} ({{app.locatif.loyer_yanport_geo.irisIds.type_label}})</span>
												<div class="text-900 font-medium text-xl"><span>{{getPrice(app.locatif.loyer_yanport_geo.irisIds.value)}}</span>&nbsp;&nbsp;<span class="text-500 font-medium mb-3">{{ $t('loyer_h.c_median') }} *</span></div>
											</div>
											<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
												<i class="pi pi-euro text-orange-500 text-xl"></i>
											</div>
										</div>
										<p v-if="app.loyer_yanport.count" class="text-500 font-medium pt-2"><span><b>{{app.locatif.loyer_yanport_geo.irisIds.count}}</b></span>
											<span class="text-500"> {{ $t('annonce') }}</span><span class="text-500" v-if="app.locatif.loyer_yanport_geo.irisIds.count>1">s</span>*
										</p>
										<span v-else class="text-500">{{ $t('aucune_annonce_na_ete_communiquee') }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style="width:2%"></div>-->
					<div class="part" style="width:50%;padding-right:20px;margin-bottom:5px !important;">
						<div class="py-1" style="padding-right:0px !important">
							<div class="grid">
								<div class="col-12">
									<div class="surface-card shadow-2 p-3 border-round">
										<div class="mb-3">
											<div class="">
												<div class="flex justify-content-between block text-900 font-medium text-xl mb-3" style="padding-bottom: 1rem;display:flex">
													{{ $t('marche_du_locatif') }} {{app.locatif.loyer_yanport_geo.cityIds.label}} ({{app.locatif.loyer_yanport_geo.cityIds.type_label}})
													<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
														<i class="pi pi-euro text-orange-500 text-xl"></i>
													</div>
												</div>
												<div class="text-900 font-medium text-xl flex justify-content-between">
													<div>
														<span>{{getPrice(app.locatif.loyer_yanport_geo.cityIds.value)}}</span>&nbsp;&nbsp;
														<span class="text-500 font-medium mb-3">{{ $t('loyer_h.c_median') }} *</span>
													</div>
													<div v-tooltip.left="'Comparaison année N-1'">
														<div v-if="app.locatif.loyer_yanport_geo.cityIds.values && app.locatif.loyer_yanport_geo.cityIds.values.points && app.locatif.loyer_yanport_geo.cityIds.values.points[app.locatif.loyer_yanport_geo.cityIds.values.points.length-1].y.values['50.0'] > app.locatif.loyer_yanport_geo.cityIds.values.points[app.locatif.loyer_yanport_geo.cityIds.values.points.length-2].y.values['50.0']">
															<div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
																<i class="pi pi-arrow-up-right text-green-500 text-xl"></i>
															</div>
														</div>
														<div v-if="app.locatif.loyer_yanport_geo.cityIds.values && app.locatif.loyer_yanport_geo.cityIds.values.points && app.locatif.loyer_yanport_geo.cityIds.values.points[app.locatif.loyer_yanport_geo.cityIds.values.points.length-1].y.values['50.0'] < app.locatif.loyer_yanport_geo.cityIds.values.points[app.locatif.loyer_yanport_geo.cityIds.values.points.length-2].y.values['50.0']">
															<div class="flex align-items-center justify-content-center bg-red-100 border-round" style="width:2.5rem;height:2.5rem">
																<i class="pi pi-arrow-down-right text-red-500 text-xl"></i>
															</div>
														</div>
														<div v-if="app.locatif.loyer_yanport_geo.cityIds.values && app.locatif.loyer_yanport_geo.cityIds.values.points && app.locatif.loyer_yanport_geo.cityIds.values.points[app.locatif.loyer_yanport_geo.cityIds.values.points.length-1].y.values['50.0'] == app.locatif.loyer_yanport_geo.cityIds.values.points[app.locatif.loyer_yanport_geo.cityIds.values.points.length-2].y.values['50.0']">
															<div class="flex align-items-center justify-content-center bg-gray-100 border-round" style="width:2.5rem;height:2.5rem">
																<i class="pi pi-pause text-gray-500 text-xl rotate-icon"></i>
															</div>
														</div>
													</div>
												</div>

												<!--
												<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
													<i class="pi pi-euro text-orange-500 text-xl"></i>
												</div>
												-->
											</div>
											<p v-if="app.loyer_yanport.count" class="text-500 font-medium pt-2"><span><b>{{app.locatif.loyer_yanport_geo.cityIds.count}}</b></span>
												<span class="text-500"> {{ $t('annonce') }}</span><span class="text-500" v-if="app.locatif.loyer_yanport_geo.cityIds.count>1">s</span>*
											</p>
											<span v-else class="text-500">{{ $t('aucune_annonce_na_ete_communiquee') }}</span>
											<div class="text-500 font-medium pt-2">
												{{ $t('Indicateur tension de l\'offre locative') }}: <span style="font-size: 18pt;font-weight:600; color:rgb(139, 142, 240)">{{offre_locative}}</span> <span style="color:rgb(139, 142, 240);font-style: italic;">{{ $t('logs. à l’offre pour 1000 ménages') }}</span>													
											</div>
										</div>
										<div class="part-aste">* {{ $t('donnees_sur_12_mois_glissants') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="part" style="width:50%;padding-right:20px;margin-bottom:5px !important;">
						<div class="py-1" style="padding-right:0px !important">
							<div class="grid">
								<div class="col-12">
									<div class="surface-card shadow-2 p-3 border-round" style="text-align: center;min-height: 255px;">
										Tension entre le budget locatif et le niveau de loyer pratiqué
										<br/>
										<br/>
										<div class="p-4" style="display: flex;justify-content: center;">
											<div style="padding-top: 40px;width: 400px;height:100px" id="heatmap_loyer">
												<div class="heatmap" v-if="budget_moyen">
													<div class="indicator" :style="{ left: vPercentage + '%' }">
														<div class="indicator-value" v-tooltip.left="'Loyer moyen H.C. pour un T3 sur la commune'">Loyer moyen : {{ vloyen_moyen }}</div>
													</div>
													<div class="indicator2" :style="{ left: vPercentage2 + '%' }">
														<div class="indicator-value2" v-tooltip.left="'Calculé sur la base du revenu médian d’un ménage de 2 personnes (25% du revenu)'">Budget locatif : {{ vbudget_moyen }}</div>
													</div>
												</div>
												<div class="heatmap" style="background: #FFFFFF !important;" v-else>
													<div>données non disponibles sur la commune </div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!--
					<div style="width:2%"></div>
					<div class="part" style="width:32%;margin-bottom:5px !important;">
						<div class="py-1" style="padding-right:0px !important">
							<div class="grid">
								<div class="col-12">
									<div class="surface-card shadow-2 p-3 border-round">
										<div class="flex justify-content-between mb-3">
											<div>
												<span class="block text-900 font-medium text-xl mb-3" style="padding-bottom: 1rem;">{{ $t('marche_du_locatif') }} {{app.locatif.loyer_yanport_geo.agglomerationIds.label}} ({{app.locatif.loyer_yanport_geo.agglomerationIds.type_label}})</span>
												<div class="text-900 font-medium text-xl"><span>{{getPrice(app.locatif.loyer_yanport_geo.agglomerationIds.value)}}</span>&nbsp;&nbsp;<span class="text-500 font-medium mb-3">{{ $t('loyer_h.c_median') }} *</span></div>
											</div>
											<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
												<i class="pi pi-euro text-orange-500 text-xl"></i>
											</div>
										</div>
										<p v-if="app.loyer_yanport.count" class="text-500 font-medium pt-2"><span><b>{{app.locatif.loyer_yanport_geo.agglomerationIds.count}}</b></span>
											<span class="text-500"> {{ $t('annonce') }}</span><span class="text-500" v-if="app.locatif.loyer_yanport_geo.agglomerationIds.count>1">s</span>*
										</p>
										<span v-else class="text-500">{{ $t('aucune_annonce_na_ete_communiquee') }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					-->
				</div>
				<!--
				<div id="locatif_dash" class="business-dashboard company-dashboard" style="padding-top: 0px !important;margin-bottom:20px;">
					<div class="business-dashboard company-dashboard" style="">
						<div class="company-header is-dark-card-bordered is-dark-bg-6">
							<p>* {{ $t('donnees_sur_12_mois_glissants') }}</p>
						</div>
					</div>
				</div>
				-->
				<!--
				<div style="width: 100%; display: flex;justify-content: center;">
					<SelectButton v-model="filterGeo" :options="justifyOptions" dataKey="value">
						<template #option="slotProps">
							<span>{{slotProps.option.libelle}}</span>
						</template>
					</SelectButton>
				</div>-->
				<div class="" v-observe-visibility="onVisibilityChange" style="display: flex;flex-wrap: wrap;justify-content: center;" v-if="app.locatif && app.locatif.yanport_typo_graph_prix_geo">
					<geo-locatif style="min-width: 800px;width: 100%;flex: 1 1 800px;padding-right: 10px;padding-bottom: 10px;" v-show="app.adr_init.type !='city'/* && filterGeo.value == 'iris'*/" 
					:heatmap="'heatmap_iris'"
					:geo="'irisIds'"
					:statgeo="app.locatif.loyer_yanport_geo.irisIds" 
					:statgeotypo="app.locatif.yanport_typo_graph_prix_geo.irisIds" 
					:statgeojour="app.locatif.yanport_typo_graph_jour_geo.irisIds"
					:statgeotypoprix="app.locatif.yanport_typo_prix_geo.irisIds"
					:statgeotypojour="app.locatif.yanport_typo_jour_geo.irisIds"
					:statgeotyporef="app.locatif.yanport_typo_ref_geo.irisIds"
					:statgeotypotranche="app.locatif.loyer_tranches_typo_geo.irisIds"
					:statgeotranche = "app.locatif.loyer_tranches_geo.irisIds"
					>
					</geo-locatif>
					<geo-locatif style="min-width: 799px;width: 100%;flex: 1 1 800px;padding-right: 10px;padding-bottom: 10px;" v-show="/*filterGeo.value == 'ville'*/true"
					:heatmap="'heatmap_ville'"
					:geo="'cityIds'"
					:statgeo="app.locatif.loyer_yanport_geo.cityIds" 
					:statgeotypo="app.locatif.yanport_typo_graph_prix_geo.cityIds" 
					:statgeojour="app.locatif.yanport_typo_graph_jour_geo.cityIds"
					:statgeotypoprix="app.locatif.yanport_typo_prix_geo.cityIds"
					:statgeotypojour="app.locatif.yanport_typo_jour_geo.cityIds"
					:statgeotyporef="app.locatif.yanport_typo_ref_geo.cityIds"
					:statgeotypotranche="app.locatif.loyer_tranches_typo_geo.cityIds"
					:statgeotranche = "app.locatif.loyer_tranches_geo.cityIds"
					>
					</geo-locatif>
					<!--
					<geo-locatif style="width:100%" v-show="filterGeo.value == 'agglo'"
					:heatmap="'heatmap_agglo'"
					:geo="'agglomerationIds'"
					:statgeo="app.locatif.loyer_yanport_geo.agglomerationIds" 
					:statgeotypo="app.locatif.yanport_typo_graph_prix_geo.agglomerationIds" 
					:statgeojour="app.locatif.yanport_typo_graph_jour_geo.agglomerationIds"
					:statgeotypoprix="app.locatif.yanport_typo_prix_geo.agglomerationIds"
					:statgeotypojour="app.locatif.yanport_typo_jour_geo.agglomerationIds"
					:statgeotyporef="app.locatif.yanport_typo_ref_geo.agglomerationIds"
					:statgeotypotranche="app.locatif.loyer_tranches_typo_geo.agglomerationIds"
					:statgeotranche = "app.locatif.loyer_tranches_geo.agglomerationIds"
					>
					</geo-locatif>
					-->
				</div>
				<div id="locatif_dash" class="business-dashboard company-dashboard" style="padding-top: 0px !important;">
					<div class="business-dashboard company-dashboard" style="">
						<div class="company-header is-dark-card-bordered is-dark-bg-6">
							<p>{{ $t('legende_heatmap') }}</p>
						</div>
					</div>
				</div>
	
	
	
	
				<div>
					<div class="part-title">{{ $t('Marché locatif social') }}</div>
					<div id="locatif_dash" class="business-dashboard company-dashboard" style="padding-top: 0px !important;">
						<div class="business-dashboard company-dashboard" style="padding: 20px;background: var(--white);border: 1px solid var(--fade-grey-dark-3);border-radius: 4px;">
							<div class="is-dark-card-bordered is-dark-bg-6" style="margin-bottom: 0.2rem;display:flex;justify-content: space-between;flex-direction: row;flex-wrap: wrap">
								<div style="width:420px">
									<div class="pb-4">Caractéristique du parc social</div>
									<table style="width:100%">
										<th style="display: none"></th>
										<tr>
											<td>Zonage social</td>
											<td style="text-align:right">
												<Chip v-tooltip.left="$t('legende_zonage')" style="font-size: 0.7rem;" v-if="app.adr_init.zonage_social" :label="'Zone ' + convertToRoman(app.adr_init.zonage_social)" class="mr-2" :class="{ z1b: convertToRoman(app.adr_init.zonage_social)=='I bis', z1: convertToRoman(app.adr_init.zonage_social)=='I', z2: convertToRoman(app.adr_init.zonage_social)=='II', z3: convertToRoman(app.adr_init.zonage_social)=='III' }" />
											</td>
										</tr>										
										<tr>
											<td colspan="2" style="height:20px">&nbsp;</td>
										</tr>
										<tr>
											<td>{{$t('Nombre')}}</td>
											<td style="text-align:right" v-if="app.rpls_commune && app.rpls_commune._source && app.rpls_commune._source.parc_complet && app.rpls_commune._source.parc_complet.ensemble_parc_social">
												{{ app.rpls_commune._source.parc_complet.ensemble_parc_social}} {{$t('logements')}}
											</td>
											<td style="text-align:right" v-else>
												-
											</td>
										</tr>
										<tr>
											<td>{{$t('Age moyen')}}</td>
											<td style="text-align:right" v-if="app.rpls_commune && app.rpls_commune._source && app.rpls_commune._source.parc_complet && Math.round(parseFloat(app.rpls_commune._source.evolution_parc_complet.age_moyen_du_parc))">
												{{ Math.round(parseFloat(app.rpls_commune._source.evolution_parc_complet.age_moyen_du_parc)) }} {{$t('ans')}}
											</td>
											<td style="text-align:right" v-else>
												-
											</td>
										</tr>
										<tr>
											<td>{{$t('Taux de vacance')}}</td>
											<td style="text-align:right" v-if="app.rpls_commune && app.rpls_commune._source && app.rpls_commune._source.parc_complet && Math.round(parseFloat(app.rpls_commune._source.vacances_mobilites.taux_vacance.totale.janvier_2022*100))/100">
												{{ Math.round(parseFloat(app.rpls_commune._source.vacances_mobilites.taux_vacance.totale.janvier_2022*100))/100 }} %
											</td>
											<td style="text-align:right" v-else>
												-
											</td>
										</tr>
										<tr>
											<td>{{$t('Loyer moyen / m²')}}</td>
											<td style="text-align:right" v-if="app.rpls_commune && app.rpls_commune._source && app.rpls_commune._source.parc_complet && Math.round(parseFloat(app.rpls_commune._source.loyers.loyer_moyen_2022_parc_complet*100))/100">
												{{ Math.round(parseFloat(app.rpls_commune._source.loyers.loyer_moyen_2022_parc_complet*100))/100 }} {{$t('€/m²')}}
											</td>
											<td style="text-align:right" v-else>
												-
											</td>
										</tr>
										<tr>
											<td>{{$t('Loyer moyen / m² PLI')}}</td>
											<td style="text-align:right" v-if="app.rpls_commune && app.rpls_commune._source && app.rpls_commune._source.parc_complet && Math.round(parseFloat(app.rpls_commune._source.loyer_moyen_par_financement.pli*100))/100">
												{{ Math.round(parseFloat(app.rpls_commune._source.loyer_moyen_par_financement.pli*100))/100 }} {{$t('€/m²')}}
											</td>
											<td style="text-align:right" v-else>
												-
											</td>
										</tr>
									</table>
								</div>
								<div style="width: 75%;">
									<div class="pb-4">Répartition du nombre de logement</div>
									<div style="margin-bottom: 0.2rem;display:flex;justify-content: space-between;flex-direction: row;flex-wrap: wrap">
										
										<div class="p-4" style="width: 33%; min-width:420px">
											<div class="pb-2">{{$t('par financement')}}</div>
											<div style="width: 400px">
												<Grad :GradTab="chartParFinancementTab" id="chartFina" />
											</div>
										</div>
										<div class="p-4" style="width: 33%; min-width:420px">
											<div class="pb-2">{{$t('par DPE')}}</div>
											<div style="width: 400px">
												<Grad :GradTab="chartParDpeTab" id="chartDpe" />
											</div>
										</div>
										<div class="p-4" style="width: 33%; min-width:420px">
											<div class="pb-2">{{$t('par typologie')}}</div>
											<div style="width: 400px">
												<Grad :GradTab="chartParTypoTab" id="chartTypo" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	
				<div v-if="app.insee_sne && app.insee_sne.length">
					<div class="part-title">{{ $t('Demande en logement locatif social') }}</div>
					<div id="locatif_dash" class="business-dashboard company-dashboard" style="padding-top: 0px !important;">
						<div class="business-dashboard company-dashboard" style="padding: 20px;background: var(--white);border: 1px solid var(--fade-grey-dark-3);border-radius: 4px;">
							<div class="is-dark-card-bordered is-dark-bg-6" style="margin-bottom: 0.2rem;display:flex;justify-content: space-between;flex-direction: row;flex-wrap: wrap">
								<div style="width: 100%;">
									
									<div class="flex align-items-center pb-2" style="vertical-align:bottom; justify-content: flex-start;">
										<div class="flex text-purple-600" style="font-size: 1.5rem;">
											<icon file="real_estate_agent" style="color:#666;height:40px;width:40px;"/>
										</div>
										<div class="pt-1" style="margin-top: 0.7rem !important;line-height:1.5rem">
											&nbsp; {{$t('Pression de la demande en logement social')}}&nbsp; :&nbsp; 
										</div>
										<div class="flex text-purple-600" style="font-size: 1.5rem;" v-if="app.insee_sne[0].data.social_demand.global.demand">
											<div class="pl-2" style="margin-top: 0.6rem !important;">{{formatnb(Math.round(app.insee_sne[0].data.social_demand.global.demand_pressure*10,1)/10)}}</div>
											<div class="pl-2 pt-1" style="margin-top: 0.7rem !important;line-height:1.5rem; font-size: 1rem;color:#495057">
												{{$t('demandes pour 1 attribution')}}
											</div>
										</div>
										<div v-else>
											<div class="pl-2 pt-1" style="margin-top: 0.7rem !important;line-height:1.5rem; font-size: 1rem;color:#495057">
												(donnée non disponible)
											</div>
										</div>
										
									</div>
									

									<br>
									<div class="pb-4">{{$t('Répartition du nombre de demande')}} <span v-if="app.insee_sne[0].data.social_demand.global.demand">({{ app.insee_sne[0].data.social_demand.global.demand}} demandes)</span><span v-else>(donnée non disponible)</span></div>
									<div style="margin-bottom: 0.2rem;display:flex;justify-content: space-between;flex-direction: row;flex-wrap: wrap">
										
										<div class="p-4" style="width: 25%; min-width:420px">
											<div class="pb-2">{{$t('par composition familiale')}}</div>
											<div style="width: 400px">
												<Grad :GradTab="chartSneFamilyTab" id="chartSneFamilyTab" />
											</div>
										</div>
										<div class="p-4" style="width: 25%; min-width:420px">
											<div class="pb-2">{{$t('par nombre de personnes dans le ménage')}}</div>
											<div style="width: 400px">
												<Grad :GradTab="chartSnePersonneMenageTab" id="chartSnePersonneMenageTab" />
											</div>
										</div>
										<div class="p-4" style="width: 25%; min-width:420px">
											<div class="pb-2">{{$t('par âge')}}</div>
											<div style="width: 400px">
												<Grad :GradTab="chartSneAgeTab" id="chartSneAgeTab" />
											</div>
										</div>
										<div class="p-4" style="width: 25%; min-width:420px">
											<div class="pb-2">{{$t('par typologie')}}</div>
											<div style="width: 400px">
												<Grad :GradTab="chartSneTypoTab" id="chartSneTypoTab" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>				

	
	
				<!--
				<div v-if="app.user.adequation">
					<div class="part-title">{{ $t('Marché locatif libre intermédiaire') }}</div>
					<div id="locatif_dash" class="business-dashboard company-dashboard" style="padding-top: 0px !important;">
						<div class="business-dashboard company-dashboard" style="padding: 20px;background: var(--white);border: 1px solid var(--fade-grey-dark-3);border-radius: 4px;">
							<div class="pb-4">{{ $t('budget locatif VS loyer libre à l’offre') }}</div>
							<div class="is-dark-card-bordered is-dark-bg-6" style="margin-bottom: 0.2rem;display:flex;justify-content: space-between;">
								<div style="width: 25%;">
									<table>
										<th style="display: none"></th>
										<tr>
											<td>Zonage social</td>
											<td style="text-align:right">
												<Chip v-tooltip.left="$t('legende_zonage')" style="font-size: 0.7rem;" v-if="app.adr_init.zonage_social" :label="'Zone ' + convertToRoman(app.adr_init.zonage_social)" class="mr-2" :class="{ z1b: convertToRoman(app.adr_init.zonage_social)=='I bis', z1: convertToRoman(app.adr_init.zonage_social)=='I', z2: convertToRoman(app.adr_init.zonage_social)=='II', z3: convertToRoman(app.adr_init.zonage_social)=='III' }" />
											
												{{app.insee[0].data[app.insee[0].data.length-1].lodgment.household.average_salary_household*0.3}}
											
											</td>
										</tr>																				
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>	
				-->
			
			</Fieldset>
		</div>
	</div>
</template>

<script>
/* eslint-disable */ 
import { mapGetters } from 'vuex'
import { ObserveVisibility  } from 'vue-observe-visibility'
import GeoLocatif from './report/GeoLocatif'
import Fieldset from 'primevue/fieldset';
import SelectButton from 'primevue/selectbutton';
import { i18n } from '../../main';
import Grad from '../global/Grad.vue';
//import Chart from 'primevue/chart';
import Chip from 'primevue/chip';

export default {
	name: 'TabLocatif',
	data: function() {
		return {
			vPercentage : 0,
			vPercentage2 : 0,
			voffre_locative : 0,
			vloyen_moyen : '',
			vbudget_moyen: '',
			budget_moyen: 0,
			chartOptionsPie_financement: {
				plugins : {
					datalabels: {
						color: 'black', // Couleur blanche pour les labels
						font: {
							size: 16
						},
						anchor: 'end', // Positionnement des labels
						align: 'start',
						clamp: true, // Assure que les labels ne sont pas coupés
						clip: false // Permet aux labels de dépasser les bords de la zone
						
					}
				},
				legend: {
                    labels: {
                        fontColor: '#FFFFFF',
						padding: {
							top: 30,
							bottom: 30
						}
                    },
					position: 'top',
                },
			},

			chartOptionsPie: {
				plugins : {
					datalabels: {
						color: 'black', // Couleur blanche pour les labels
						font: {
							size: 16, // Taille de la police à 12px
						}
					}
				}
			},

			justifyOptions: [
                {icon: 'pi pi-align-left', value: 'iris', libelle: 'IRIS'},
                {icon: 'pi pi-align-center', value: 'ville', libelle: 'VILLE'},
               // {icon: 'pi pi-align-justify', value: 'agglo', libelle: 'AGGLOMERATION'}
			   ],
			v: [],
			filterGeo: {icon: 'pi pi-align-left', value: 'iris', libelle: 'IRIS'},
			activeSection2 : '',
			tab: 'tvap',
			unan: 0,
			deuxans: 0,
			chartOptions: {
				chart: {
					type: 'heatmap',
				},
				dataLabels: {
					enabled: false
				},
				colors: ["#008FFB"],
				xaxis: {
					type: 'category',
					categories: []
				},
			},
			series: [],
			options : {
				chart: {
					height: 400,
					type: 'line',
					stacked: false,
						toolbar: {
							show: false,
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					width: [4, 4, 4, 4, 4],
					curve: 'smooth'
				},
				xaxis: {
					categories: [2014, 2015, 2016],
				},
				yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
				],
				tooltip: {
					fixed: {
						enabled: true,
						position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
						offsetY: 30,
						offsetX: 60
					},
				},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40
				},
				series: [],
				title: {
					text: 'Ajax Example',
				},
				noData: {
					text: 'Loading...'
				}
				},		
			Prix: {
				series: [{
					name: 'T1',
					type: 'line',
					data: [2.8, 3.8, 4.6]
				}, {
					name: 'T2',
					type: 'line',
					data: [4.9, 6.5, 8.5]
				}, {
					name: 'T3',
					type: 'line',
					data: [3, 4, 6.5]
				}, {
					name: 'T4',
					type: 'line',
					data: [1.1, 3.1, 4.1]
				}, {
					name: 'T5',
					type: 'line',
					data: [4, 2, 6]
				}],
				chartOptions: {
					chart: {
						height: 400,
						type: 'line',
						stacked: false,
						toolbar: {
							show: false,
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						width: [4, 4, 4, 4, 4],
						curve: 'smooth'
					},
					xaxis: {
						categories: [],
					},
					yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
					],
					tooltip: {
						fixed: {
							enabled: true,
							position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
							offsetY: 30,
							offsetX: 60
						},
					},
					legend: {
						horizontalAlign: 'left',
						offsetX: 40
					}
				},
			},
			
			Duree: {
				
				series: [{
					name: 'T1',
					type: 'line',
					data: [2.8, 3.8, 4.6]
				}, {
					name: 'T2',
					type: 'line',
					data: [4.9, 6.5, 8.5]
				}, {
					name: 'T3',
					type: 'line',
					data: [3, 4, 6.5]
				}, {
					name: 'T4',
					type: 'line',
					data: [1.1, 3.1, 4.1]
				}, {
					name: 'T5',
					type: 'line',
					data: [4, 2, 6]
				}],				
				chartOptions: {
					chart: {
						height: 400,
						type: 'line',
						stacked: false,
						toolbar: {
							show: false,
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						width: [4, 4, 4, 4, 4],
						curve: 'smooth'
					},
					xaxis: {
						categories: [2014, 2015, 2016],
					},
					yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
					],
					tooltip: {
						fixed: {
							enabled: true,
							position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
							offsetY: 30,
							offsetX: 60
						},
					},
					legend: {
						horizontalAlign: 'left',
						offsetX: 40
					}
				},
			},

			usersOptions : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					sparkline: {
						enabled: true,
					},
					toolbar: {
						show: false,
						offsetX: 0,
						offsetY: 0,
						tools: {
							download: true,
							selection: true,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true | '<img src="/static/icons/reset.png" width="20">',
							customIcons: []
						},
						export: {
							csv: {
								filename: undefined,
								columnDelimiter: ',',
								headerCategory: 'category',
								headerValue: 'value',						
							},
							svg: {
								filename: undefined,
							},
							png: {
								filename: undefined,
							}
						},
						autoSelected: 'zoom' 
					},			},
					colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
					grid: {
						show: false,
						padding: {
							left: 0,
							right: 0,
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						width: [2],
						curve: 'smooth',
					},
					xaxis: {
						type: 'numeric',
						lines: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
						},
					},
					yaxis: [
					{
						y: 0,
						offsetX: 0,
						offsetY: 0,
						labels: {
							show: false,
						},
						padding: {
							left: 0,
							right: 0,
						},
					},
					],
					tooltip: {
						x: {
							show: false,
							format: 'dd/MM/yy HH:mm',
						},
					},
				},
				
				usersOptionsJour : {
					series: [
					{
						name: 'Loyers médians',
						data: [15.48, 15.79, 16.2],
					},
					],
					chart: {
						height: 100,
						type: 'area',
						toolbar: {
							show: false,
						},
						sparkline: {
							enabled: true,
						},
					},
					colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
					grid: {
						show: false,
						padding: {
							left: 0,
							right: 0,
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						width: [2],
						curve: 'smooth',
					},
					xaxis: {
						type: 'numeric',
						lines: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
						},
					},
					yaxis: [
					{
						y: 0,
						offsetX: 0,
						offsetY: 0,
						labels: {
							show: false,
						},
						padding: {
							left: 0,
							right: 0,
						},
					},
					],
					tooltip: {
						x: {
							show: false,
							format: 'dd/MM/yy HH:mm',
						},
					},
				},
				
				options : { /* eslint-disable-line */
					
					chart: {
						style: {
							fontFamily: 'Nunito',
						},
						toolbar: {
							show: true,
							offsetX: 0,
							offsetY: 0,
							tools: {
								download: true,
								selection: false,
								zoom: false,
								zoomin: false,
								zoomout: false,
								pan: false,
								reset: false | '<img src="/static/icons/reset.png" width="20">',
								customIcons: []
							}
						},
						height: 350,
						type: "line",
						stacked: false
					},
					dataLabels: {
						enabled: false
					},
					colors: ['#99C2A2', '#C5EDAC', '#66C7F4'],
					series: [
					
					{
						name: i18n.t('annonces'),
						type: 'column',
						data: [17,27,32]
					},
					{
						name: i18n.t('loyer'),
						type: 'line',
						data: [15.8, 14.78, 14.84]
					},
					],
					stroke: {
						width: [4, 4, 4]
					},				
					plotOptions: {
						bar: {
							columnWidth: "20%"
						}
					},
					xaxis: {
						categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
					},
					yaxis: [
					{
						seriesName: 'Column A',
						axisTicks: {
							show: true
						},
						axisBorder: {
							show: true,
						},
						title: {
							text: i18n.t('annonces')
						}
					},
					{
						opposite: true,
						seriesName: 'Line C',
						axisTicks: {
							show: true
						},
						axisBorder: {
							show: true,
						},
						title: {
							text: i18n.t('loyer')
						}
					}
					],
					tooltip: {
						shared: false,
						intersect: true,
						x: {
							show: false
						}
					},
					legend: {
						horizontalAlign: "left",
						offsetX: 40
					}
				},
				
				sharingOptions: {
					series: [
					{
						name: i18n.t('annonces'),
						data: [],
					},
					],
					chart: {
						height: 100,
						type: 'bar',
						toolbar: {
							show: false,
						},
						sparkline: {
							enabled: true,
						},
					},
					colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
					plotOptions: {
						bar: {
							columnWidth: '50px',
							distributed: true,
							endingShape: 'rounded',
						},
					},
					dataLabels: {
						enabled: false,
					},
					legend: {
						show: false,
					},
					xaxis: {
						categories: [2020,2021,2022],					
						lines: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
						},
					},
					yaxis: [
					{
						y: 0,
						offsetX: 0,
						offsetY: 0,
						labels: {
							show: false,
						},
						padding: {
							left: 0,
							right: 0,
						},
					},
					],
				},
				
				chartOptions_trim: {
					title: '',
					chart: {
						height: 280,
						type: 'areaspline',
						style: {
							fontFamily: 'Nunito',
						}
					},
					series: [
					{
						color: '#FFD501',
						name: i18n.t('annonces'),
						data: [0,0,0,0],
						type: 'column',
						yAxis: 0,
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
							[0, '#FFD501'],
							[1, 'transparent']
							]
						},
						marker: {
							symbol: 'square',
							enabled: false
						}
					},{
						color: '#048855',
						name: "Loyers",
						data: [0,0,0,0],
						yAxis: 1,
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
							[0, 'rgba(4,136,85,0.3)'],
							[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					
					],
					xAxis: {
						type : 'category',
						categories : ['', '', ''],
						title: {
							text: ''
						},
						allowDecimals: false,
						lineWidth: 1,
						lineColor: '#ddd',
					},
					yAxis: [
					{
						title: {
							text: i18n.t('annonces')
						},
						allowDecimals: true,
						lineWidth: 0,
						gridLineColor: '#ddd',
						gridLineDashStyle: 'dash',
						labels: {
							format: '{value}'
						},
						opposite: true
					},{
						title: {
							text: 'Loyer H.C.'
						},
						allowDecimals: true,
						lineWidth: 0,
						gridLineColor: '#ddd',
						gridLineDashStyle: 'dash',
						labels: {
							format: '{value:,.0f} €'
						}
					},
					],
					plotOptions: {
						series: {
							label: {
								connectorAllowed: false
							},
						}
					},
					tooltip: {
						shared: true,
						crosshairs: true,
						backgroundColor: '#fff',
						borderWidth: 0,
						borderRadius: 4
					},
				},
				
				reference : {
					name:'Logement T3', 
					typeId:2, 
					rooms: 3, 
					buildingConstruction: 7, 
					condition: 3, 
					parkingOpen: 1, 
					area: 60
				},
			fu_visible: false,
			detail: '',
		}
	},
	watch: {
			'app.loyer_yanport.label': function() {
				this.maj_graph()
			},
			'app.yanport_typo_graph_jour_time': function(newValue) {
				if (newValue) {
					this.maj_graph()
				}
			},
			'app.yanport_typo_graph_prix_time': function(newValue) {
				if (newValue) {
					this.maj_graph()
				}
			},
			
		},

	computed: Object.assign(mapGetters([ 'app', 'report' ]), {
		offre_locative() {
			let n = 0
			if (
				this.app.insee && 
				this.app.insee.length>0 && 
				this.app.insee[0] && 
				this.app.insee[0].data && 
				this.app.insee[0].data.length && 
				this.app.insee[0].data[this.app.insee[0].data.length-1] &&
				this.app.insee[0].data[this.app.insee[0].data.length-1].household &&
				this.app.insee[0].data[this.app.insee[0].data.length-1].household.total_household &&
				this.app.insee_offre_locative
			) {
				n = Math.round((this.app.insee_offre_locative*100000) / this.app.insee[0].data[this.app.insee[0].data.length-1].household.total_household)/100
			}
			return n
		}, 

		chartParFinancementTab:function(){
			let v = [
				{l: 'PLAI', a:['plai'], color: "#af7ac5"},
				{l: 'PLUS', a:['plus_av_1977', 'plus_ap_1977'], color: "#c39bd3"},
				{l: 'PLS', a:['pls'], color: "#d7bde2"},
				{l: 'PLI', a:['pli'], color: "#ebdef0"},
			]
			if (this.app.rpls_commune && this.app.rpls_commune._source && this.app.rpls_commune._source.parc_complet && this.app.rpls_commune._source.parc_complet.fincancement_origine) {
				return this.setTab(v, this.app.rpls_commune._source.parc_complet.fincancement_origine)	
			} else {
				return this.setTab(v, false)	
			}
			
		},

		chartParDpeTab:function(){
			let v = [
				{l: 'A/B', a:['A', 'B'], color: "#3db044"},
				{l: 'C/D', a:['C', 'D'], color: "#e6fe52"},
				{l: 'E', a:['E'], color: "#fdcc3d"},
				{l: 'F', a:['F'], color: "#fd9c45"},
				{l: 'G', a:['G'], color: "#fb1c19"},
				{l: 'NR', a:['NR'], color: "#e7e7e7"},
			]
			if (this.app.rpls_commune && this.app.rpls_commune._source && this.app.rpls_commune._source.dpe_energie) {
				return this.setTab(v, this.app.rpls_commune._source.dpe_energie)
			} else {
				return this.setTab(v, false)	
			}
		},

		chartParTypoTab:function(){
			let v = [
				{l: 'T1', a:['_1'], color: "#008FFB"},
				{l: 'T2', a:['_2'], color: "#00E396"},
				{l: 'T3', a:['_3'], color: "#FEB019"},
				{l: 'T4', a:['_4'], color: "#FF4560"},
				{l: 'T5 et +', a:['_5'], color: "#7c74c4"}
			]
			if (this.app.rpls_commune && this.app.rpls_commune._source && this.app.rpls_commune._source.parc_complet && this.app.rpls_commune._source.parc_complet.nombre_pieces) {
				return this.setTab(v, this.app.rpls_commune._source.parc_complet.nombre_pieces)
			} else {
				return this.setTab(v, false)	
			}
		},

		chartSneFamilyTab:function(){
			let v = [
				{l: 'familles', a:['family'], color: "#fcf3cf"},
				{l: 'familles monoparentales', a:['single_parent'], color: "#f7dc6f"},
				{l: 'personnes isolées', a:['single_person'], color: "#f1c40f"},
			]
			return this.setTab(v, this.app.insee_sne[0].data.social_demand.division[3].family_division, 'demand')
		},

		chartSnePersonneMenageTab:function(){
			let v = [
				{l: '1 personne', a:['household_size_1 '], color: "#cd6155"},
				{l: '2 personnes', a:['household_size_2 '], color: "#d98880"},
				{l: '3 personnes', a:['household_size_3 '], color: "#e6b0aa"},
				{l: '4 personnes', a:['household_size_4 '], color: "#f2d7d5"},
				{l: '5 personnes et +', a:['household_size_5 ', 'household_size_6 '], color: "#f9ebea"},
			]
			return this.setTab(v, this.app.insee_sne[0].data.social_demand.division[2].household_division, 'demand')	
		},

		chartSneAgeTab:function(){
			let v = [
				{l: '- de 30 ans', a:['age_0_30'], color: "#eaf2f8"},
				{l: '30-39 ans', a:['age_30_39'], color: "#d4e6f1"},
				{l: '40-49 ans', a:['age_40_49'], color: "#a9cce3"},
				{l: '50-59 ans', a:['age_50_59'], color: "#7fb3d5"},
				{l: '60-74 ans', a:['age_60_74'], color: "#5499c7"},
				{l: '75 ans et +', a:['age_75_more'], color: "#2980b9"}
			]
			return this.setTab(v, this.app.insee_sne[0].data.social_demand.division[0].age_division, 'demand')
		},

		chartSneTypoTab:function(){
			let v = [
				{l: 'T1', a:['t1'], color: "#008FFB"},
				{l: 'T2', a:['t2'], color: "#00E396"},
				{l: 'T3', a:['t3'], color: "#FEB019"},
				{l: 'T4', a:['t4'], color: "#FF4560"},
				{l: 'T5 et +', a:['t5'], color: "#7c74c4"}
			]
			return this.setTab(v, this.app.insee_sne[0].data.social_demand.division[1].typology, 'demand')	
		},

		getAnnee:function(){
			let chartOptions = {
				chart: {
					height: 400,
					type: 'line',
					stacked: false,
					toolbar: {
						show: false,
					},
					dropShadow: {
						enabled: true,
						color: '#000',
						top: 18,
						left: 7,
						blur: 10,
						opacity: 0.2
					},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					width: [4, 4, 4, 4, 4],
					curve: 'smooth'
				},
				xaxis: {
					categories: this.v,
				},
				yaxis: [
				{
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
					},
					tooltip: {
						enabled: true
					}
				},
				],
				tooltip: {
					fixed: {
						enabled: true,
						position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
						offsetY: 30,
						offsetX: 60
					},
				},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40
				}
			}
			return chartOptions
		}
	}),
	
	directives: {
		'observe-visibility': ObserveVisibility
	},

	methods: {
		offre_locative1() {
			let n = 0
			if (
				this.app.insee && 
				this.app.insee.length>0 && 
				this.app.insee[0] && 
				this.app.insee[0].data && 
				this.app.insee[0].data.length && 
				this.app.insee[0].data[this.app.insee[0].data.length-1] &&
				this.app.insee[0].data[this.app.insee[0].data.length-1].household &&
				this.app.insee[0].data[this.app.insee[0].data.length-1].household.total_household &&
				this.app.insee_offre_locative
			) {
				n = Math.round((this.app.insee_offre_locative*100000) / this.app.insee[0].data[this.app.insee[0].data.length-1].household.total_household)/100
			}
			this.voffre_locative = n
		}, 

		indicatorPosition() {
			/*
			this.vPercentage = 0;
			if (
				this.app.locatif.yanport_typo_graph_prix && 
				this.app.locatif.yanport_typo_graph_prix.length>3 && 
				this.app.locatif.yanport_typo_graph_prix[3] &&
				this.app.locatif.yanport_typo_graph_prix[3].points && 
				this.app.locatif.yanport_typo_graph_prix[3].points.length &&
				this.app.locatif.yanport_typo_graph_prix[3].points[this.app.locatif.yanport_typo_graph_prix[3].points.length-1] &&
				this.app.locatif.yanport_typo_graph_prix[3].points[this.app.locatif.yanport_typo_graph_prix[3].points.length-1].y &&
				this.app.locatif.yanport_typo_graph_prix[3].points[this.app.locatif.yanport_typo_graph_prix[3].points.length-1].y.values
			) {
				this.vPercentage = (
				(
					(this.app.locatif.yanport_typo_graph_prix[3].points[this.app.locatif.yanport_typo_graph_prix[3].points.length-1].y.values["50.0"] * 62)
				) / 
				(
					(this.app.insee[0].data[4].household.household_division.household_2p.decile.decile_5.income * 0.25 * 2)
				)) * 100;
			}
			*/
			this.vPercentage2 = 0;
			if (
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds && 
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds.length>3 && 
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3] &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points && 
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1] &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1].y &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1].y.values
			) {
				this.vPercentage2 = (
				(
					(this.app.insee[0].data[4].household.household_division.household_2p.decile.decile_5.income * 0.25)
				) / 
				(
					(this.app.insee[0].data[4].household.household_division.household_2p.decile.decile_5.income * 0.25 * 2)
				)) * 100;
			}

			this.vPercentage = 0;
			if (
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds && 
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds.length>3 && 
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3] &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points && 
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1] &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1].y &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1].y.values
			) {
				this.vPercentage = (
				(
					(this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1].y.values["50.0"] * 62)
				) / 
				(
					(this.app.insee[0].data[4].household.household_division.household_2p.decile.decile_5.income * 0.25 * 2)
				)) * 100;
			}
		},

		loyen_moyen() {
			let n = 0;
			
			if (
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds && 
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds.length>3 && 
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3] &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points && 
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1] &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1].y &&
				this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1].y.values
			) {
				n = Math.round(this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points[this.app.locatif.yanport_typo_graph_prix_geo.cityIds[3].points.length-1].y.values["50.0"] * 62/10)*10
			}
			/*
			if (
				this.app.locatif.yanport_typo_graph_prix && 
				this.app.locatif.yanport_typo_graph_prix.length>3 && 
				this.app.locatif.yanport_typo_graph_prix[3] &&
				this.app.locatif.yanport_typo_graph_prix[3].points && 
				this.app.locatif.yanport_typo_graph_prix[3].points.length &&
				this.app.locatif.yanport_typo_graph_prix[3].points[this.app.locatif.yanport_typo_graph_prix[3].points.length-1] &&
				this.app.locatif.yanport_typo_graph_prix[3].points[this.app.locatif.yanport_typo_graph_prix[3].points.length-1].y &&
				this.app.locatif.yanport_typo_graph_prix[3].points[this.app.locatif.yanport_typo_graph_prix[3].points.length-1].y.values
			) {
				n = Math.round(this.app.locatif.yanport_typo_graph_prix[3].points[this.app.locatif.yanport_typo_graph_prix[3].points.length-1].y.values["50.0"] * 62/10)*10
			}
			*/
			this.vloyen_moyen = n.toString() + "€"
			this.budget_moyen = Math.round(this.app.insee[0].data[4].household.household_division.household_2p.decile.decile_5.income * 0.25/10)*10
			this.vbudget_moyen = Math.round(this.app.insee[0].data[4].household.household_division.household_2p.decile.decile_5.income * 0.25/10)*10 + "€"
			
		},

		formatnb: function(p) {
			let nPrice=p
			
			const euro = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 0
            });
			if (nPrice==0) {
				return "-"
			} else {
                return euro.format(nPrice)
			}
		},

		setTab: function(v, e, a) {

			// Fonction pour calculer la valeur absolue pour un élément donné
			const getAbsolueValue = (element) => {

				// Si `element.a` est un tableau, on additionne les valeurs correspondantes pour chaque clé dans `element.a`
				if (Array.isArray(element.a)) {
					return element.a.reduce((sum, key) => {
							
						// Si `a` existe, on cherche `e[key][a]`, sinon on prend `e[key]` directement
						return sum + (e[key] ? Number(a ? e[key][a] || 0 : e[key] || 0) : 0);
					}, 0);
				}

				// Si `element.a` est une chaîne unique
				return e[element.a] ? Number(a ? e[element.a][a] || 0 : e[element.a] || 0) : 0;
			};

			// Calcul du total en une seule passe
			let total = 0
			if (e) {
				total = v.reduce((sum, element) => {
					return sum + getAbsolueValue(element);
				}, 0);
			}

			// Création du tableau des résultats
			let t = v.map(element => {

				// Calcul de la valeur absolue
				let absolue = 0
				if (e) {
					absolue = getAbsolueValue(element);
				}

				// Retour de l'objet avec les données formatées
				return {
					l: element.l,
					absolue: absolue,
					percent: total > 0 ? Math.round((absolue / total) * 100) : 0,
					color: element.color
				};
			});

			return this.setpercentdisplay(t);
		},

		setpercentdisplay: function(t) {
			let n = 0;
			for (let index = 0; index < t.length; index++) {
				const element = t[index];
				if (element.absolue > n) n = element.absolue
			}
			for (let index = 0; index < t.length; index++) {
				t[index].percentdisplay = t[index].absolue / n * 100
			}
			return t
		},

		convertToRoman: function(num) {
			if (num=='01 bis' || num=='1 bis' || num=='I bis') return 'I bis'
			if (num=='1' || num=='01' || num=='I') return 'I'
			if (num=='2' || num=='02' || num=='II') return 'II'
			if (num=='3' || num=='03' || num=='III') return 'III' 
			return 'NC'
		},

		GetT(typo) {
			let t=[]
			if (this.app.locatif && this.app.locatif.loyer_tranches_typo_geo && this.app.locatif.loyer_tranches_typo_geo.irisIds && this.app.locatif.loyer_tranches_typo_geo.irisIds[typo] && this.app.locatif.loyer_tranches_typo_geo.irisIds[typo].points) {
				for (let index = 0; index < this.app.locatif.loyer_tranches_typo_geo.irisIds[typo].points.length-1; index++) {
					const element = this.app.locatif.loyer_tranches_typo_geo.irisIds[typo].points[index];
					t.push(element.y.count)
				}
			}
			return t
		},
		
		onVisibilityChange(isVisible) {
			if (isVisible) {
				// Nothing
			} else {
				if (this.activeSection2 == 'taboptim') {
					this.activeSection2 = ''
				}
			}
		},

		getTitreLocatif: function() {
			if (this.app.loyer_yanport && this.app.loyer_yanport.values && this.app.loyer_yanport.values.points[0]) return { titre: { title: i18n.t('type') }, annee_1: { title: this.app.loyer_yanport.values.points[0].x.substring(0, 4) }, annee_2: { title: this.app.loyer_yanport.values.points[1].x.substring(0, 4) }, annee_3: { title: this.app.loyer_yanport.values.points[2].x.substring(0, 4) }}
			else return { titre: { title: i18n.t('type') }, annee_1: { title: '' }, annee_2: { title: '' }, annee_3: { title: '' }}
		},

		getPrice: function(p) {
			if (p=="NaN") {
				return "-"
			} else {
				return Math.round(p*100)/100 + " €"
			}
		},

		setBiensComparables: function(p) {
			this.$store.dispatch('set_bienslocatifs', { value: p })
		},

		isNumber: function(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},

		setContour: function(el) {
			this.$store.dispatch('set_contour_loyer', { contour: el })
		},

		DonneLibelleReference: function(filter) {
			switch (filter) {
				case 'typologie':
					return 'Logement T' + this.app.cityscan.realty.rooms
				case 'forme':
					if (this.app.cityscan.realty.typeId==1) {
						return 'Maison'
					} else {
						return 'Appartement'
					}
				case 'etat':
					switch (this.app.cityscan.realty.condition) {
						case 1:
							return 'Refait à neuf'
						case 2:
							return 'Rafraîchi'
						case 3:
							return 'Standard'
						case 4:
							return 'A rafraîchir'
						default:
							return 'A refaire'
					}
				case 'parking':
					if (this.app.cityscan.realty.parkingOpen==1) {
						return 'Parking inclus'
					} else {
						return 'Sans parking'
					}
				default:
					break;
			}
		},

		DonneValeurPrix: function(val) {
			const euro = new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			});
			if (val==0) {
				return "-"
			} else {
				return euro.format(val)
			}
		},
		
		details: function(d) {
			if (this.detail==d) {
				this.detail=''
			}
			else {
				this.detail=d
			}
		},

		updateSeriesLine: function(dataPrix, dataCategories) {
			this.series = dataPrix
			let chartOptions= {
				chart: {
					type: 'heatmap',
					toolbar: {
						show: false
					}
				},
				dataLabels: {
					enabled: false
				},
				colors: ["#008FFB"],
				xaxis: {
					type: 'category',
					categories: dataCategories
				},
			}
			this.chartOptions = chartOptions
			this.v = []
		},

		maj_graph: function() {
			this.loyen_moyen()
			this.indicatorPosition()
			this.offre_locative1()

			if (this.app.loyer_yanport && this.app.loyer_yanport.values && this.app.loyer_yanport.values.points[0]) {
				let dataLoyer= [{
					name: 'T1',
					data: this.GetT(1)
				},
				{
					name: 'T2',
					data: this.GetT(2)
				},
				{
					name: 'T3',
					data: this.GetT(3)
				},
				{
					name: 'T4',
					data: this.GetT(4)
				},
				{
					name: 'T5',
					data: this.GetT(5)
				}
				]
				let dataCategories = []
				if (this.app.locatif && this.app.locatif.loyer_tranches_geo && this.app.locatif.loyer_tranches_geo.irisIds) {
					let Indicemax = this.app.locatif.loyer_tranches_geo.irisIds.length-1
					for (let index = 0; index < Indicemax; index++) {						
						const element = this.app.locatif.loyer_tranches_geo.irisIds[index];
						const elementS = this.app.locatif.loyer_tranches_geo.irisIds[index+1];
						dataCategories.push(element.toString() + ' € - ' + elementS.toString() + ' €')
						
					}
				}
				this.updateSeriesLine(dataLoyer, dataCategories);
			}
		},
	},

	mounted: function() {
		let that = this
		that.maj_graph()
	},

	components: { GeoLocatif, Fieldset, SelectButton, /*Chart,*/ Chip, Grad }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

#chart
	width 100%

.program-detail-info-filters
	text-align center
	margin 0 0 8px 0

.tab-content
	padding 24px 32px
	.part
		margin 0 0 40px 0
.part-title
	margin 0 0 8px 0
	line-height 32px
	font-weight 600
	text-transform uppercase
	font-size 16px
.tabs2
	position fixed
	top 61px
	left 0px
	left 60px
	color secondary
	background white
	border-bottom 1px solid hsl(0deg 0% 86%)
	border-left 1px solid hsl(0deg 0% 86%)
	&:after
		content ''
		display block
		clear both
	.tab
		float left
		margin 0 0px 0 0
		height 60px
		width 180px
		display flex
		align-items center
		justify-content center
		flex-direction column
		opacity 1
		font-size 12px
		font-weight 400
		border-bottom 4px solid transparent
		text-align center
		text-transform uppercase
		cursor pointer
		color #a1a5b8
		&:hover
			color #666
		&.active
			opacity 2
			border-bottom-color var(--primary)
			color var(--primary)
		.icon
			display block
			margin 0 0 2px 0

.padleft
	padding-left 15px !important
.padtop
	padding-top 15px !important
.dropdown-menu
	left auto
	right 0
	position absolute
	top 100%
	z-index 20
	margin-top 68px
	box-shadow 0 5px 16px hsl(0deg, 0%, 93%)
	border-color hsl(0deg, 0%, 93%)
	padding-top 0
	min-width 260px
	display block
.dropdown-content
	background-color #fff
	border-radius 4px
	box-shadow none
	padding-bottom 0.5rem
	border 1px solid hsl(0deg, 0%, 93%)
    padding-top 0
    overflow hidden

.dropdown-head
	display flex
	align-items center
	padding 28px 16px
	margin-bottom 12px
	background #f1f1f1

.dropdown.is-spaced .dropdown-item.is-media
	display flex
	align-items center
	
.dropdown .dropdown-menu .dropdown-item
	color hsl(232deg, 14%, 68%)
	font-family "Roboto", sans-serif

.dropdown-item
	padding 0.5rem 1rem
	font-size 0.95rem
	color hsl(232deg, 14%, 68%)
	transition all 0.3s
a.dropdown-item, button.dropdown-item
	padding-right 3rem
	text-align inherit
	white-space nowrap
	width 100%

.dropdown-item
	color hsl(0deg, 0%, 29%)
	display block
	font-size 0.875rem
	line-height 1.5
	padding 0.375rem 1rem
	position relative

.dropdown-divider
	background-color hsl(0deg, 0%, 93%)
	border none
	display block
	height 1px
	margin 0.5rem 0

.button.v-button:not([disabled])
	cursor pointer

.button.is-fullwidth
	display flex
	width 100%

.button.is-primary
	background-color palette0
	border-color transparent
	color #ededed
	&:hover
		opacity 0.9
		box-shadow var(--primary-box-shadow)

.tabs
	position absolute
	right 24px
	top 24px
	.tab
		display inline-block
		margin 0 0 0 4px
		padding 0 12px
		border-radius 40px
		line-height 24px
		font-size 12px
		color #333
		cursor pointer
		&:hover
			background-color light
		&.active
			background blue
			color #fff
.tabs_other
	position absolute
	right 24px
	top 12px
	.tab
		display inline-block
		margin 0 0 4px 4px
		padding 0 12px
		border-radius 40px
		line-height 24px
		font-size 12px
		color #333
		cursor pointer
		font-weight 500
		&:hover
			background-color light
		&.active
			background grey
			color #fff
.stat-box-content
	display block
	padding 8px

.zon
	border-radius 5px
	padding-right 5px
	padding-left 5px
	line-height 24px
	margin-right 5px
.z1b
	background #d92c21
	color #fff
.z1
	background #efae1f
	color #fff
.z2
	background #7ab2d5
	color #fff
.z3
	background #fdfdfd
.zabis
	background #d92c21
	color #fff
.za	
	background #f5aa21
	color #fff
.zb1
	background #649770
	color #fff
.zb2
	background #aacde1
	color #fff
.zc
	background #ffffff
.red
	color red
.green
	color #32CD32

.part-aste
	font-size 12px
	padding-top 10px
	width 100%
	text-align end

	
.dashboard-tile {
	width: 20%;
	min-width: 180px;
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	font-family: var(--font), sans-serif;
	margin-right: 10px;
}
.dashboard-tile2 {
	padding: 10px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	font-family: var(--font), sans-serif;
}
.dashboard-tile .tile-head {
	display: flex;
	align-items: center;
	justify-content: space-between;

}
.dashboard-tile .tile-body {
	font-size: 2rem;
	padding: 4px 0 8px;
	text-align: center
}
.dashboard-tile .tile-foot span:first-child {
	font-weight: 500;
}

.text-h-green {
	color: var(--green);
	font-size: 24px;
}

.text-h-red {
	color: var(--red);
	font-size: 24px;
}

.text-h-p {
	color: #6c21f9;
	font-size: 24px;
}

.dashboard-tile .tile-foot span:nth-child(2) {
	color: var(--light-text);
	font-size: 0.9rem;
}

.is-4 {
	flex: none;
	width: 25%;
	padding: 0.75rem;
}
.is-2 {
	width: 50%;
}

.le {
	color: var(--light-text);
	font-size: 0.9rem;
	text-align: right;
	position: absolute;
	right: 0px;
}


.columns {
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	margin-top: -0.75rem;
}
.columns:last-child {
	margin-bottom: -0.75rem;
}
.columns:not(:last-child) {
	margin-bottom: calc(1.5rem - 0.75rem);
}
.columns.is-centered {
	justify-content: center;
}
.columns.is-gapless {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
}
.columns.is-gapless > .column {
	margin: 0;
	padding: 0 !important;
}
.columns.is-gapless:not(:last-child) {
	margin-bottom: 1.5rem;
}
.columns.is-gapless:last-child {
	margin-bottom: 0;
}
.columns.is-mobile {
	display: flex;
}
.columns.is-multiline {
	flex-wrap: wrap;
}
.columns.is-vcentered {
	align-items: center;
}



.company-dashboard .company-header {
	display: flex;
	padding: 20px;
	background: var(--white);
	border: 1px solid var(--fade-grey-dark-3);
	border-radius: 4px;
	margin-bottom: 0.2rem;
}
.company-dashboard .company-header .header-item {
	width: 50%;
	border-right: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .company-header .header-item:last-child {
	border-right: none;
}
.company-dashboard .company-header .header-item .item-inner {
	text-align: center;
}
.company-dashboard .company-header .header-item .item-inner .lnil, .company-dashboard .company-header .header-item .item-inner .lnir {
	font-size: 1.8rem;
	margin-bottom: 6px;
	color: var(--primary);
}

.lnil {
	display: inline-block;
	font: normal normal normal 1em/1 'LineIconsPro Light';
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.company-dashboard .company-header .header-item .item-inner span {
	display: block;
	font-family: var(--font), sans-serif;
	font-weight: 600;
	font-size: 1.6rem;
	color: var(--dark-text);
}
.company-dashboard .company-header .header-item .item-inner p {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.85rem;
}


.r {
	font-family: var(--font-alt), sans-serif !important;
	font-size: 0.95rem !important;
	font-family: var(--font), sans-serif !important;
	font-weight: 300 !important;
	color: var(--light-text) !important;
	display: initial !important;
}


.company-dashboard .dashboard-card.is-base-chart {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.company-dashboard .dashboard-card {
	flex: 1;
	display: inline-block;
	width: 100%;
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	height: 100%;
}
.company-dashboard .dashboard-card.is-base-chart .content-box {
	padding: 30px;
}
.company-dashboard .dashboard-card.is-base-chart .chart-container {
	margin-top: auto;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats {
	display: flex;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat {
	margin-right: 30px;
	font-family: var(--font), sans-serif;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:first-child {
	text-transform: uppercase;
	font-family: var(--font-alt), sans-serif;
	font-size: 0.75rem;
	color: var(--light-text);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span {
	display: block;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span.current {
	color: var(--primary);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:nth-child(2) {
	color: var(--dark-text);
	font-size: 1.6rem;
	font-weight: 600;
}
.revenue-stat {
	width: 33%
}
.chart-container {
}
.dark-inverted2 {
	font-size: 1.4rem !important;
}
.rotate-icon {
	display: inline-block;
	transform: rotate(90deg);
}

.heatmap {
	width: 400px;
	height: 20px;
	background: linear-gradient(to right, #3db044, #3db044, #3db044, #3db044, #e6fe52, #e6fe52, #fdcc3d, #fd9c45, #fb1c19);
	border-radius: 20px;
	position: relative;
}

/* Style de la barre d'indication de valeur */
.indicator {
	position: absolute;
	top: -5px;
	width: 2px;
	height: calc(100% + 5px);	
	background-color: black;
	border-radius: 2px;
}

.indicator2 {
	position: absolute;
	top: 0;
	width: 2px;
	height: calc(100% + 5px);
	background-color: black;
	border-radius: 2px;
}

/* Optionnel: Pour afficher la valeur au-dessus de la barre */
.indicator-value {
	text-wrap: nowrap;
	position: absolute;
	top: -20px;
	transform: translateX(-50%);
	background-color: black;
	color: white;
	padding: 2px 5px;
	font-size: 12px;
	border-radius: 3px;
}

/* Optionnel: Pour afficher la valeur au-dessus de la barre */
.indicator-value2 {
	text-wrap: nowrap;
	position: absolute;
	bottom: -20px;
	transform: translateX(-50%);
	background-color: black;
	color: white;
	padding: 2px 5px;
	font-size: 12px;
	border-radius: 3px;
}
</style>
