<template>
	<div class="pane pane-map" :class="[ app.viewlayer, {'full': !app.menuvisible, 'fil': app.view=='fil'}]">
		<div class="map-content" :class="{ selection: map.mode == 'selection' }">
			<map-osm />
			<button-report />
			<button-adresse />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MapOsm from './pane-map/MapOsm'
import ButtonReport from './pane-map/ButtonReport'
import ButtonAdresse from './pane-map/ButtonAdresse.vue'

export default {
	name: 'PaneMap',
	
	data: function() {
		return {
			mode: 'consultation'
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {

	}),

	components: { MapOsm, ButtonReport , ButtonAdresse	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.full
	width 100% !important
.menu-visible
	width 100% !important
.fil
	width 50% !important
.pane-map
	position absolute
	right 0
	top headersize
	height calc(100% - 126px)
	width calc(100% - 350px)
	transition 0.5s easeOutQuart
	.map-content
		height 100%
		width 100%
		&.selection
			.mode-selection-bar-top, 
			.mode-selection-bar-left, 
			.mode-selection-bar-bottom, 
			.mode-selection-bar-right
				position absolute
				z-index 500
				background url(../assets/img/stripe.png)
				transition 0.5s easeOutQuart
			.mode-selection-bar-top
				left 0
				top 0
				width 100%
				height 4px
			.mode-selection-bar-left
				left 0
				top 0
				height 100%
				width 4px
			.mode-selection-bar-bottom
				right 0
				bottom 0
				width 100%
				height 4px
			.mode-selection-bar-right
				right 0
				top 0
				height 100%
				width 4px
			
</style>
