<template>
	<transition name="user">
		<div class="pane pane-user" style="overflow-x: hidden; overflow-y: hidden;">
			<div class="page-content-wrapper">
				<div class="account-wrapper">
					<div class="columns">
						<div class="column fixed-column" style="width: 25%;">
							<div class="account-box is-navigation">
								<div class="media-flex-center">
									<div class="v-avatar is-large"></div>
									<div class="flex-meta">
										<span>{{user.NomPrenom}}</span>
									</div>
									<div class="flex-end"></div>
								</div>
								<div class="account-menu">
									<a href="#" @click="scrollToElement2('profil')" class="router-link-active account-menu-item">
										<icon file="user2" :size="16" />
										<i aria-hidden="true" class="lnil lnil-user-alt"></i>
										<span>{{ $t('general') }}</span>
										<span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
									</a>
									<a href="#" @click="scrollToElement2('cgu')" class="router-link-active account-menu-item">
										<icon file="user2" :size="16" />
										<i aria-hidden="true" class="lnil lnil-user-alt"></i>
										<span>{{ $t('CGU et Droits RGPD') }}</span>
										<span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
									</a>
									<a href="#" @click="scrollToElement2('abonnement')" class="account-menu-item">
										<icon file="settings" :size="16" />
										<i aria-hidden="true" class="lnil lnil-crown-alt"></i>
										<span>{{ $t('abonnement') }}</span>
										<span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
									</a>
									<a href="#" @click="scrollToElement2('historique')" class="account-menu-item">
										<icon file="activity" :size="16" />
										<i aria-hidden="true" class="lnil lnil-quill"></i>
										<span>{{ $t('historique_de_commande') }}</span>
										<span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
									</a>
									<a href="#" @click="$store.dispatch('reset_view')" class="account-menu-item">
										<icon file="logout" :size="16" />
										<i aria-hidden="true" class="lnil lnil-quill"></i>
										<span>{{ $t('sortir_de_mon_compte') }}</span>
										<span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
									</a>
								</div>
							</div>
						</div>
						<div class="column scrollable-column" style="width:75%;padding-left:40px;">
							<div class="divider" id="profil">
								&nbsp;
							</div>
							<div class="account-box is-form is-footerless" v-bar>
									<div class="form-head stuck-header">
									<div class="form-head-inner">
										<div class="left">
											<h3>{{ $t('general') }}</h3>
											<p>{{ $t('mon_profil') }}</p>
										</div>
									</div>
								</div>
								<div class="form-body">
									<div class="nom">{{ $t('nom_&_prenom') }}</div>
									<div class="value">{{user.NomPrenom}}</div>
									<div class="nom">{{ $t('e-mail') }}</div>
									<div class="value">{{user.Mail}}</div>
									<div class="dropdown-item">
										<button type="button" class="button v-button is-fullwidth is-raised is-primary logout-button" role="menuitem" aria-hidden="false">
											<a href="https://utilisateurs.adequation.fr/reset-password-general?from=F7FC5BC0-6312-4696-AF6C-9A6E5E05D686">
												<icon file="logout" :size="18" />&nbsp;
												<span> {{ $t('modifier_mon_mot_de_passe') }} </span>
											</a>
										</button>
									</div>
									<div class="logo"><img alt="logo" :src="user.logo"></div>
								</div>
							</div>

							<div class="divider" id="cgu">
								&nbsp;
							</div>
							<div class="account-box is-form is-footerless">
									<div class="form-head stuck-header">
									<div class="form-head-inner">
										<div class="left">
											<h3>{{ $t('CGU et Droits RGPD') }}</h3>
											<p></p>
										</div>
									</div>
								</div>
								<div class="form-body">
									<div style="display: flex;justify-content: flex-start;flex-wrap: nowrap;flex-direction: row;">
										<div style="width:auto;margin-right:10px;">
											<a target="_blank" href="https://www.myadequation.fr/EFOCUS/CGU_V1.html">
												<Button :label="$t('Consulter les CGU et les dispositions relatives au RGPD')" icon="pi pi-list" />
											</a>
										</div>
										<div style="width:auto;">
											<Button :label="$t('Exercer mes droits RGPD')" icon="pi pi-shield"  @click="dialog_rgpd = true"/>
										</div>
									</div>
								</div>
							</div>
							
							<Dialog :visible.sync="dialog_rgpd" :modal="true" :style="{width: '40vw'}" :header="$t('Exercer mes droits RGPD')" >
								<div class="flex flex-wrap border-top-1 surface-border pt-4">
									<div class="bg-blue-50 flex align-items-center justify-content-center py-3 px-0 w-full md:w-4 border-round">
										<icon file="blocks/illustration/security" alt="Image" style="width: 243px; height: 183px;" />
									</div>
									<div class="text-700 line-height-3 m-0 p-5 w-full md:w-8">
										<span>{{$t('texte_consequence_rgpd') }}</span>
										<br>
										<span style="color:red">{{$t('Il ne pourra donc plus se connecter.') }}</span>
										<br><br>
										<div>
											<Textarea style="width:100%" v-model="mes" rows="5" cols="30" />
										</div>
									</div>
								</div>
								<template #footer>
									<div class="border-top-1 surface-border pt-3">
										<Button icon="pi pi-times" @click="dialog_rgpd = false" label="Annuler" class="p-button-text"></Button>
										<Button icon="pi pi-check" @click="dialog_rgpd = false; applique_rgpd();" label="Confirmer la suppression"></Button>
									</div>
								</template>
							</Dialog>
							
							<div class="divider" id="abonnement">
								&nbsp;
							</div>
							<div class="account-box is-form is-footerless">
								<div class="form-head stuck-header">
									<div class="form-head-inner">
										<div class="left">
											<h3>{{ $t('abonnement') }}</h3>
											<p>{{ $t('parametres') }}</p>
										</div>
									</div>
								</div>
								<div class="form-body">
									
									<div class="switch-block">
										<InputSwitch v-model="AcceLiveData" />
										<div class="text">
											<label class="" for="v-field-50">
												<span id="v-field-50">{{ $t('acces_au_live_data') }}</span>
											</label>
										</div>
									</div>
									<br>
									<div class="nom">{{ $t('territoire_dabonnement') }}</div>
									<div class="value2" v-for="(terr, index) in user.territoires" v-bind:key="'P_' + index + '_' + terr">{{terr}}</div>
<!--									<div class="value2" v-for="terr in user.territoires" v-bind:key="terr">{{terr}}</div>-->
									<br>
									<div class="switch-block">
										<InputSwitch v-model="module_cityscan" />
										<div class="text">
											<label class="" for="v-field-60">
												<span id="v-field-60">{{ $t('acces_aux_modules_additionnels_(qualite_de_ladresse,_ancien,_locatif)') }}</span>
											</label>
										</div>
									</div>
									<br>									
									<div class="nom">{{ $t('territoire_dabonnement') }}</div>
									<div class="value2" v-for="(terr, index) in user.lib_ter_mod" v-bind:key="'M_' + index + '_' + terr">
<!--									<div class="value2" v-for="terr in user.lib_ter_mod" v-bind:key="terr">-->
										<span v-if="terr=='ALL'">{{ $t('france_entiere') }}</span>
										<span v-else>{{terr}}</span>
									</div>
									<br>
									<div class="switch-block">
										<InputSwitch v-model="AccesRS" />
										<div class="text">
											<label class="" for="v-field-70">
												<span id="v-field-70">{{ $t('acces_aux_modules_residences_services') }}</span>
											</label>
										</div>
									</div>
									<br>									
									<div class="nom">{{ $t('territoire_dabonnement') }}</div>
									<div class="value2" v-for="(terr, index) in user.lib_ter_rss" v-bind:key="'R_' + index + '_' + terr">
<!--									<div class="value2" v-for="terr in user.lib_ter_rss" v-bind:key="terr">-->
										<span v-if="terr=='ALL'">{{ $t('france_entiere') }}</span>
										<span v-else>{{terr}}</span>
									</div>
								</div>
							</div>
							<div class="divider" id="historique">
								&nbsp;
							</div>
							<div class="account-box is-form is-footerless">
									<div class="form-head stuck-header">
									<div class="form-head-inner">
										<div class="left">
											<h3>{{ $t('historique') }}</h3>
											<p>{{ $t('de_commande') }}</p>
										</div>
									</div>
								</div>
								<div class="panecontent2">
									<div class="form-body" style="">
										<div style="height: 100%;width: 100%;">
											<div >
												<div style="width:100% !important;">
													<div ref="scrollable_programs">
														
														<DataTable :value="Liste" :paginator="true" class="p-datatable-customers" :rows="5"
															dataKey="id" :rowHover="true" :filters.sync="filters" :loading="loading"
															paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5, 10, 20]"
															currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
															:globalFilterFields="['his_label','created_at']" responsiveLayout="scroll">
															<template #header>
																<div class="flex justify-content-between align-items-center">
																	<h5 class="m-0">Etudes</h5>
																	<span class="p-input-icon-left">
																		<i class="pi pi-search" style="z-index:999" />
																		<InputText v-model="filters['global'].value" placeholder="Recherche" />
																	</span>
																</div>
															</template>
															<template #empty>
																{{$t('Aucune étude imprimé')}}
															</template>
															<template #loading>
																{{$t('Chargement des études')}}
															</template>
															
															<Column field="created_at" header="Date" sortable :styles="{'min-width': '11rem'}">
																<template #body="{data}">
																	{{formatDate(data.created_at)}}
																</template>
																<template #filter="{filterModel}">
																	<Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
																</template>
															</Column>
															<Column field="his_label" header="Secteurs" sortable :styles="{'min-width': '14rem'}">
																<template #body="{data}">
																	{{data.his_label}}
																</template>
																<template #filter="{filterModel}">
																	<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Recherche par secteur"/>
																</template>
															</Column>
															<Column :headerStyle="{'min-width': '4rem', 'text-align': 'center'}" :bodyStyle="{'text-align': 'center', overflow: 'visible'}">
																<template  #body="{data}">
																	<Button type="button" icon="pi pi-download" @click="openInNewTab(data.his_url)"></Button>
																</template>
															</Column>
														</DataTable>	
														<div class="divider" style="height: 160px;">
															&nbsp;
														</div>
													
													</div>
												</div>
											</div>			
										</div>
									</div>
								</div>
							</div>
							<!-- Repeat similar blocks for CGU, Abonnement, Historique, etc. -->

						</div>
					</div>
				</div>
			</div>
		</div>































		<!--
		<div class="pane pane-user" style="overflow-x: hidden; overflow-y: auto;">
			<div class="page-content-wrapper">
				<div class="account-wrapper">
					<div class="columns">
						<div class="column is-4">
							<div class="account-box is-navigation">
								<div class="media-flex-center">
									<div class="v-avatar is-large">
									</div>
									<div class="flex-meta">
										<span>{{user.NomPrenom}}</span>
									</div>
									<div class="flex-end"></div>
								</div>
								<div class="account-menu">
									<a href="#" @click="scrollToElement2('profil')" class="router-link-active account-menu-item" :class="{ routerlinkexactactive: (menu=='profil') }">
										<icon file="user2" :size="16" />
										<i aria-hidden="true" class="lnil lnil-user-alt"></i>
										<span>{{ $t('general') }}</span>
										<span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
									</a>
									<a href="#" @click="scrollToElement2('cgu')" class="router-link-active account-menu-item" :class="{ routerlinkexactactive: (menu=='cgu') }">
										<icon file="user2" :size="16" />
										<i aria-hidden="true" class="lnil lnil-user-alt"></i>
										<span>{{ $t('CGU et Droits RGPD') }}</span>
										<span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
									</a>
									<a href="#" @click="scrollToElement2('abonnement')" class="account-menu-item" :class="{ routerlinkexactactive: (menu=='abonnement') }">
										<icon file="settings" :size="16" />
										<i aria-hidden="true" class="lnil lnil-crown-alt"></i>
										<span>{{ $t('abonnement') }}</span>
										<span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
									</a>
									<a href="#" @click="scrollToElement2('historique')" class="account-menu-item" :class="{ routerlinkexactactive: (menu=='historique') }">
										<icon file="activity" :size="16" />
										<i aria-hidden="true" class="lnil lnil-quill"></i>
										<span>{{ $t('historique_de_commande') }}</span>
										<span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
									</a>
									<a href="#" @click="$store.dispatch('reset_view')" class="account-menu-item">
										<icon file="logout" :size="16" />
										<i aria-hidden="true" class="lnil lnil-quill"></i>
										<span>{{ $t('sortir_de_mon_compte') }}</span>
										<span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
									</a>
								</div>
							</div>
						</div>
						<div class="column is-8">
							<div class="account-box is-form is-footerless" id="profil">
									<div class="form-head stuck-header">
									<div class="form-head-inner">
										<div class="left">
											<h3>{{ $t('general') }}</h3>
											<p>{{ $t('mon_profil') }}</p>
										</div>
									</div>
								</div>
								<div class="form-body">
									<div class="nom">{{ $t('nom_&_prenom') }}</div>
									<div class="value">{{user.NomPrenom}}</div>
									<div class="nom">{{ $t('e-mail') }}</div>
									<div class="value">{{user.Mail}}</div>
									<div class="dropdown-item">
										<button type="button" class="button v-button is-fullwidth is-raised is-primary logout-button" role="menuitem" aria-hidden="false">
											<a href="https://utilisateurs.adequation.fr/reset-password-general?from=F7FC5BC0-6312-4696-AF6C-9A6E5E05D686">
												<icon file="logout" :size="18" />&nbsp;
												<span> {{ $t('modifier_mon_mot_de_passe') }} </span>
											</a>
										</button>
									</div>
									<div class="logo"><img alt="logo" :src="user.logo"></div>
								</div>
							</div>

							<div class="account-box is-form is-footerless" id="cgu">
									<div class="form-head stuck-header">
									<div class="form-head-inner">
										<div class="left">
											<h3>{{ $t('CGU et Droits RGPD') }}</h3>
											<p></p>
										</div>
									</div>
								</div>
								<div class="form-body">
									<div style="display: flex;justify-content: flex-start;flex-wrap: nowrap;flex-direction: row;">
										<div style="width:auto;margin-right:10px;">
											<a target="_blank" href="https://www.myadequation.fr/EFOCUS/CGU_V1.html">
												<Button :label="$t('Consulter les CGU et les dispositions relatives au RGPD')" icon="pi pi-list" />
											</a>
										</div>
										<div style="width:auto;">
											<Button :label="$t('Exercer mes droits RGPD')" icon="pi pi-shield"  @click="dialog_rgpd = true"/>
										</div>
									</div>
								</div>
							</div>
							
							<Dialog :visible.sync="dialog_rgpd" :modal="true" :style="{width: '40vw'}" :header="$t('Exercer mes droits RGPD')" >
								<div class="flex flex-wrap border-top-1 surface-border pt-4">
									<div class="bg-blue-50 flex align-items-center justify-content-center py-3 px-0 w-full md:w-4 border-round">
										<icon file="blocks/illustration/security" alt="Image" style="width: 243px; height: 183px;" />
									</div>
									<div class="text-700 line-height-3 m-0 p-5 w-full md:w-8">
										<span>{{$t('texte_consequence_rgpd') }}</span>
										<br>
										<span style="color:red">{{$t('Il ne pourra donc plus se connecter.') }}</span>
										<br><br>
										<div>
											<Textarea style="width:100%" v-model="mes" rows="5" cols="30" />
										</div>
									</div>
								</div>
								<template #footer>
									<div class="border-top-1 surface-border pt-3">
										<Button icon="pi pi-times" @click="dialog_rgpd = false" label="Annuler" class="p-button-text"></Button>
										<Button icon="pi pi-check" @click="dialog_rgpd = false; applique_rgpd();" label="Confirmer la suppression"></Button>
									</div>
								</template>
							</Dialog>
							
							<div class="account-box is-form is-footerless" id="abonnement">
									<div class="form-head stuck-header">
									<div class="form-head-inner">
										<div class="left">
											<h3>{{ $t('abonnement') }}</h3>
											<p>{{ $t('parametres') }}</p>
										</div>
									</div>
								</div>
								<div class="form-body">
									
									<div class="switch-block">
										<InputSwitch v-model="AcceLiveData" />
										<div class="text">
											<label class="" for="v-field-50">
												<span id="v-field-50">{{ $t('acces_au_live_data') }}</span>
											</label>
										</div>
									</div>
									<br>
									<div class="nom">{{ $t('territoire_dabonnement') }}</div>
									<div class="value2" v-for="terr in user.territoires" v-bind:key="terr">{{terr}}</div>
									<br>
									<div class="switch-block">
										<InputSwitch v-model="module_cityscan" />
										<div class="text">
											<label class="" for="v-field-60">
												<span id="v-field-60">{{ $t('acces_aux_modules_additionnels_(qualite_de_ladresse,_ancien,_locatif)') }}</span>
											</label>
										</div>
									</div>
									<br>									
									<div class="nom">{{ $t('territoire_dabonnement') }}</div>
									<div class="value2" v-for="terr in user.lib_ter_mod" v-bind:key="terr">
										<span v-if="terr=='ALL'">{{ $t('france_entiere') }}</span>
										<span v-else>{{terr}}</span>
									</div>
									<br>
									<div class="switch-block">
										<InputSwitch v-model="AccesRS" />
										<div class="text">
											<label class="" for="v-field-70">
												<span id="v-field-70">{{ $t('acces_aux_modules_residences_services') }}</span>
											</label>
										</div>
									</div>
									<br>									
									<div class="nom">{{ $t('territoire_dabonnement') }}</div>
									<div class="value2" v-for="terr in user.lib_ter_rss" v-bind:key="terr">
										<span v-if="terr=='ALL'">{{ $t('france_entiere') }}</span>
										<span v-else>{{terr}}</span>
									</div>
								</div>
							</div>
							<div class="account-box is-form is-footerless" id="historique">
									<div class="form-head stuck-header">
									<div class="form-head-inner">
										<div class="left">
											<h3>{{ $t('historique') }}</h3>
											<p>{{ $t('de_commande') }}</p>
										</div>
									</div>
								</div>
								<div class="panecontent2" v-bar>
									<div class="form-body" style="">
										<div style="height: 100%;width: 100%;">
											<div class="programs-list">
												<div class="programs-list-content" style="width:100% !important;" v-bar>
													<div class="content" ref="scrollable_programs">
														
														<DataTable :value="Liste" :paginator="true" class="p-datatable-customers" :rows="10"
															dataKey="id" :rowHover="true" :filters.sync="filters" :loading="loading"
															paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
															currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
															:globalFilterFields="['his_label','created_at']" responsiveLayout="scroll">
															<template #header>
																<div class="flex justify-content-between align-items-center">
																	<h5 class="m-0">Etudes</h5>
																	<span class="p-input-icon-left">
																		<i class="pi pi-search" />
																		<InputText v-model="filters['global'].value" placeholder="Keyword Search" />
																	</span>
																</div>
															</template>
															<template #empty>
																Aucune étude imprimé
															</template>
															<template #loading>
																Chargement des études
															</template>
															
															<Column field="created_at" header="Date" sortable :styles="{'min-width': '8rem'}">
																<template #body="{data}">
																	{{formatDate(data.created_at)}}
																</template>
																<template #filter="{filterModel}">
																	<Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
																</template>
															</Column>
															<Column field="his_label" header="Secteurs" sortable :styles="{'min-width': '14rem'}">
																<template #body="{data}">
																	{{data.his_label}}
																</template>
																<template #filter="{filterModel}">
																	<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Recherche par secteur"/>
																</template>
															</Column>
															<Column :headerStyle="{'min-width': '4rem', 'text-align': 'center'}" :bodyStyle="{'text-align': 'center', overflow: 'visible'}">
																<template #body>
																	<Button type="button" icon="pi pi-cog"></Button>
																</template>
															</Column>
														</DataTable>														
													</div>
												</div>
											</div>			
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	-->


	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line
import PaneEtude from './pane-user/PaneEtude.vue'
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
// eslint-disable-next-line
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
// eslint-disable-next-line
import {FilterMatchMode,FilterOperator} from 'primevue/api';

export default {
	name: 'PaneUser',

	data: function() {
		return {
			menu : 'profil',
			dmin: '',
			dmax: '',
			vmin:0,
			vmax:0,
			Liste:[],
			dialog_rgpd: false,
			mes:'',
			filters: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
			},
			loading: false,
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'user']), {
		AcceLiveData: function () {
			return !this.user.nolive
		},
		module_cityscan: function () {
			return this.user.module_cityscan
		},
		module_foncier: function () {
			return this.user.module_foncier
		},
		AccesRS: function () {
			return this.user.rs
		},
	}),

	mounted: function () {
		let d1 = new Date();
		let j = "0" + d1.getDate()
		let m = "0" + (d1.getMonth()+1)
		this.dmin = d1.getFullYear()-1 + "-" + m.substr(m.length - 2) + "-" + j.substr(j.length - 2)
		this.dmax = d1.getFullYear() + "-" + m.substr(m.length - 2) + "-" + j.substr(j.length - 2)

		this.vmin = this.dmin.split("-")[0] + this.dmin.split("-")[1] + this.dmin.split("-")[2]
		this.vmax = this.dmax.split("-")[0] + this.dmax.split("-")[1] + this.dmax.split("-")[2]
		this.majListe()
	},

	watch: {
		'app.ReportList': function() {
			this.majListe()
		},
	},

	methods: {
		openInNewTab(url) {
			window.open(url, '_blank');
		},
		scrollToElement2(elementId) {
			const element2 = document.getElementById(elementId);
			element2.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
		},

		formatDate(value) {
			return value.substring(0,16).replace('T', ' - ');
			/*
            return value.toLocaleDateString('fr-FR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
			*/
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        },
		applique_rgpd: function(){
			this.$store.dispatch('send_event_backend', {module: 'user', action: 'rgpd', option: 'Exercer mes droits RGPD'});
			this.$store.dispatch('applique_rgpd', { from: this.user.Mail, content: this.mes })
			this.mes = ""
			this.$toast.add({severity:'success', summary: 'Votre demande', detail:'a été transmise à nos services et sera traitée d’ici 48h', life: 5000});
		},
		majListe: function(){
			this.Liste = []
			if (this.app.ReportList) {
				for (let n=0; n<this.app.ReportList.length; n++) {
					if (this.app.ReportList[n].created_at>=this.vmin && this.app.ReportList[n].created_at<=this.vmax) {
						this.Liste.push(this.app.ReportList[n])
					}
				}
			}
			this.Liste.sort((a, b) => {
					if (a.created_at > b.created_at) {
						return -1;
					}
					if (a.created_at < b.created_at) {
						return 1;
					}
					return 0;
				});
		},

		BoolVersChaine: function(b) {
			if (b) {
				return "OUI"
			} else {
				return "NON"
			}
		},

		changemin: function() {			
			this.vmin = this.dmin.split("-")[0] + this.dmin.split("-")[1] + this.dmin.split("-")[2]
			this.majListe()
		},

		changemax: function() {
			this.vmax = this.dmax.split("-")[0] + this.dmax.split("-")[1] + this.dmax.split("-")[2]
			this.majListe()
		}
	},

	// eslint-disable-next-line
	components: { PaneEtude, Button, Dialog, Textarea, InputSwitch, DataTable, Column, ColumnGroup, InputText }
}
</script>


<style lang="stylus" scoped>
@import '../assets/css/app'



.fixed-column {
    position: fixed;
	left: 10px;
    top: 70px;
    height: 100%;
    overflow-y: auto;
    width: 25%; /* Ajuster selon les besoins */
}

.scrollable-column {
    margin-left: 25%; /* Ajuster selon les besoins pour correspondre à la largeur de la colonne fixe */
    overflow-y: auto;
    height: 100vh;
    padding-left: 1rem;
}

.user-enter, .user-leave-to
	transform translateX(-100%)

.opacity-enter, .opacity-leave-to
	opacity 0

.full
	max-height 500px
._filtre
	padding 20px
	border-bottom 1px solid #ccc
	height 60px
	vertical-align middle

.content
	height calc(100% - 100px)

.profil
	border 1px solid #ccc
	border-radius 5px
	padding 20px
	font-weight 500

.value
	color #999
	padding-bottom 20px

.value2
	color #999
	padding-bottom 5px

.menu
	vertical-align middle
	text-align center
	height 48px
	padding-top 16px
	vertical-align middle !important
	cursor pointer
	display inline-block
	&:hover
		background-color violetlogo
		color #fff
.encours
	color violetlogo
	font-weight 500
	font-size: 1.5em
	padding-top 10px

.pane-user
	background-color #f0f0f0
	position absolute
	top 60px
	float left
	padding-top 25px
	height calc(100% - 115px)
	width 100%
	bottom 0
	transition 0.5s easeOutQuart
	z-index 500
	.programs
		height 100%
		overflow hidden
		background #fff
		border-right 1px solid #ddd
		.programs-list
			height 100%
			.programs-list-content
				height 100%
	.loading
		position absolute
		right 50%
		top 50%
	.loading-lib
		top -30px
		left 100px
	.infinite-loader
		position absolute
		.loader
			display inline-block
			background main_color
			border-radius 100%
			color #333
			.icon
				display block
				animation rotate 1.5s infinite linear
	.logo
		position absolute
		right 16px
		top 16px
		padding 0
		height 150px
		border-radius 12px
		overflow hidden
		img
			height 100%
			max-height 150px
	.home-search
		position absolute
		left 50%
		top calc(50% - 64px)
		transform translate(-50%, -50%)
		color #333
		.slogan
			margin 0 0 24px 0
			white-space nowrap
			font-family volte, sans-serif
			font-size 20px
			text-align center
		.form
			width 615px
			margin 0 auto
			.input
				background #fff
				border-radius 40px
				input
					display block
					width 100%
					height 48px
					margin 0 4px 0 0
					padding 0 24px
					border none
					background none
					background-size 24px 24px
					outline none
					font inherit
					color #333
					&::-webkit-input-placeholder
						font-weight 400
				button
					position absolute
					right 0
					top 0
					height 48px
					width 48px
					border none
					background none
					border-radius 40px
					outline none
					cursor pointer
					opacity 0.5
					&:hover, &:active
						opacity 0.75
			.hint
				padding 0 0 0 32px
				margin 8px 0 0 0
				opacity 0.35
				font-size 12px
				line-height 16px
				.icon
					position absolute
					left 0
					top 50%
					transform translateY(-50%)
	.home-user-history
		position absolute
		left 24px
		bottom 24px
		color #fff
		.history-label
			opacity 0.5
			font-family volte, sans-serif
			font-size 14px
			text-transform uppercase
			line-height 24px
		.history-value
			> span
				display inline-block
				padding 4px 16px 4px 12px
				margin 0 0 0 -12px
				cursor pointer
				border-radius 40px
				line-height 24px
				&:hover
					background-color alpha(#fff, 10%)
				.icon
					float left
					margin 0 8px 0 0

.buttonv
	right 0
	border 0
	border-radius 0
	background palette1
	border-radius 18px
	font-style normal
	font-weight 500
	font-size 14px
	line-height 16px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width auto
	padding-left 20px
	padding-right 20px
	height 36px
	cursor pointer
	&:hover
		background palette0


.biens
	height calc(100% - 40px)
	font-size .95rem


.bien
	height 90px
	width 100%


.panecontent2
	overflow hidden
	height calc(100% - 60px)







.is-navbar .account-wrapper {
	margin-top: 30px;
}
.account-wrapper {
	padding-bottom: 60px;
}
.account-wrapper .account-box {
	flex: 1;
	display: inline-block;
	width: 100%;
	padding: 20px;
	background-color: var(--white);
	border-radius: 8px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
}
.account-wrapper .account-box.has-margin-bottom {
	margin-bottom: 1.5rem;
}
.account-wrapper .account-box.is-flat {
	box-shadow: none;
}
.account-wrapper .account-box.is-raised {
	box-shadow: var(--light-box-shadow);
}
.account-wrapper .account-box.is-primary {
	background: var(--primary);
	border-color: var(--primary);
	color: var(--primary--color-invert);
}
.account-wrapper .account-box.is-primary .title,
.account-wrapper .account-box.is-primary .subtitle,
.account-wrapper .account-box.is-primary h1,
.account-wrapper .account-box.is-primary h2,
.account-wrapper .account-box.is-primary h3,
.account-wrapper .account-box.is-primary h4,
.account-wrapper .account-box.is-primary p {
	color: var(--smoke-white) !important;
}
.account-wrapper .account-box.is-secondary {
	background: var(--secondary) !important;
	border-color: var(--secondary) !important;
}
.account-wrapper .account-box.is-secondary .title,
.account-wrapper .account-box.is-secondary .subtitle,
.account-wrapper .account-box.is-secondary h1,
.account-wrapper .account-box.is-secondary h2,
.account-wrapper .account-box.is-secondary h3,
.account-wrapper .account-box.is-secondary h4,
.account-wrapper .account-box.is-secondary p {
	color: var(--smoke-white) !important;
}
.account-wrapper .account-box.is-info {
	background: var(--info) !important;
	border-color: var(--info) !important;
}
.account-wrapper .account-box.is-info .title,
.account-wrapper .account-box.is-info .subtitle,
.account-wrapper .account-box.is-info h1,
.account-wrapper .account-box.is-info h2,
.account-wrapper .account-box.is-info h3,
.account-wrapper .account-box.is-info h4,
.account-wrapper .account-box.is-info p {
	color: var(--smoke-white) !important;
}
.account-wrapper .account-box.is-success {
	background: var(--success) !important;
	border-color: var(--success) !important;
}
.account-wrapper .account-box.is-success .title,
.account-wrapper .account-box.is-success .subtitle,
.account-wrapper .account-box.is-success h1,
.account-wrapper .account-box.is-success h2,
.account-wrapper .account-box.is-success h3,
.account-wrapper .account-box.is-success h4,
.account-wrapper .account-box.is-success p {
	color: var(--smoke-white) !important;
}
.account-wrapper .account-box.is-warning {
	background: var(--warning) !important;
	border-color: var(--warning) !important;
}
.account-wrapper .account-box.is-warning .title,
.account-wrapper .account-box.is-warning .subtitle,
.account-wrapper .account-box.is-warning h1,
.account-wrapper .account-box.is-warning h2,
.account-wrapper .account-box.is-warning h3,
.account-wrapper .account-box.is-warning h4,
.account-wrapper .account-box.is-warning p {
	color: var(--smoke-white) !important;
}
.account-wrapper .account-box.is-danger {
	background: var(--danger) !important;
	border-color: var(--danger) !important;
}
.account-wrapper .account-box.is-danger .title,
.account-wrapper .account-box.is-danger .subtitle,
.account-wrapper .account-box.is-danger h1,
.account-wrapper .account-box.is-danger h2,
.account-wrapper .account-box.is-danger h3,
.account-wrapper .account-box.is-danger h4,
.account-wrapper .account-box.is-danger p {
	color: var(--smoke-white) !important;
}
.account-wrapper .account-box .card-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.account-wrapper .account-box .card-head .left,
.account-wrapper .account-box .card-head .right {
	display: flex;
	align-items: center;
}
.account-wrapper .account-box .card-head .left .title {
	font-size: 1.2rem;
	font-weight: 500;
}
.account-wrapper .account-box .card-head .right {
	justify-content: flex-end;
}
.account-wrapper .account-box .card-inner {
	padding-top: 1.75rem;
}
.account-wrapper .account-box.is-navigation .media-flex-center {
	padding-bottom: 20px;
}
.account-wrapper .account-box.is-navigation .media-flex-center .flex-meta span:first-child {
	font-size: 1.3rem;
}
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item {
	display: flex;
	align-items: center;
	padding: 12px 16px;
	border: 1px solid transparent;
	border-radius: 8px;
	margin-bottom: 5px;
	transition: all 0.3s;
}
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item.routerlinkexactactive {
	box-shadow: var(--light-box-shadow);
	border-color: var(--fade-grey-dark-3);
}
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item.routerlinkexactactive span,
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item.routerlinkexactactive i {
	color: var(--primary);
}
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item.routerlinkexactactive .end {
	display: block;
}
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item:not(.routerlinkexactactive):hover {
	background: var(--fade-grey-light-3);
}
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item i {
	margin-right: 8px;
	font-size: 1.1rem;
	color: var(--light-text);
}
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item i.fas,
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item i .fal,
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item i .far {
	font-size: 0.9rem;
}
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item span {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.95rem;
	color: var(--dark-text);
}
.account-wrapper .account-box.is-navigation .account-menu .account-menu-item .end {
	margin-left: auto;
	display: none;
}
.account-wrapper .account-box.is-form {
	padding: 0;
}
.account-wrapper .account-box.is-form.is-footerless {
	padding-bottom: 20px;
}
.account-wrapper .account-box.is-form .form-head,
.account-wrapper .account-box.is-form .form-foot {
	padding: 12px 20px;
}
.account-wrapper .account-box.is-form .form-head .form-head-inner,
.account-wrapper .account-box.is-form .form-head .form-foot-inner,
.account-wrapper .account-box.is-form .form-foot .form-head-inner,
.account-wrapper .account-box.is-form .form-foot .form-foot-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.account-wrapper .account-box.is-form .form-head {
	border-bottom: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
}
.account-wrapper .account-box.is-form .form-head.is-stuck {
	background: var(--white);
	padding-right: 80px;
	border-left: 1px solid var(--fade-grey-dark-3);
}
.account-wrapper .account-box.is-form .form-head .left h3 {
	font-family: var(--font-alt), sans-serif;
	font-size: 1.2rem;
	line-height: 1.3;
}
.account-wrapper .account-box.is-form .form-head .left p {
	font-size: 0.95rem;
}
.account-wrapper .account-box.is-form .form-foot {
	border-top: 1px solid var(--fade-grey-dark-3);
}
.account-wrapper .account-box.is-form .form-body {
	padding: 20px;
}
.account-wrapper .account-box.is-form .form-body .fieldset {
	padding: 20px 0;
	max-width: 480px;
	margin: 0 auto;
}
.account-wrapper .account-box.is-form .form-body .fieldset .fieldset-heading {
	margin-bottom: 20px;
}
.account-wrapper .account-box.is-form .form-body .fieldset .fieldset-heading h4 {
	font-family: var(--font-alt), sans-serif;
	font-weight: 600;
	font-size: 1rem;
}
.account-wrapper .account-box.is-form .form-body .fieldset .fieldset-heading p {
	font-size: 0.9rem;
}
.account-wrapper .account-box.is-form .form-body .fieldset .v-avatar {
	position: relative;
	display: block;
	margin: 0 auto;
}
.account-wrapper .account-box.is-form .form-body .fieldset .v-avatar .edit-button {
	position: absolute;
	bottom: 0;
	right: 0;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-form {
	text-align: center;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-form .filepond-profile-wrap {
	margin: 0 auto 10px !important;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .icon-wrap {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	min-width: 50px;
	height: 50px;
	border-radius: var(--radius-rounded);
	background: var(--fade-grey-light-2);
	border: 1px solid var(--fade-grey-dark-3);
	color: var(--light-text);
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .icon-wrap.has-img {
	border-color: var(--primary);
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .icon-wrap.has-img img {
	width: 36px;
	min-width: 36px;
	height: 36px;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .icon-wrap i {
	font-size: 1.4rem;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item img {
	display: block;
	width: 50px;
	min-width: 50px;
	height: 50px;
	border-radius: var(--radius-rounded);
	border: 1px solid transparent;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .meta {
	margin-left: 10px;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .meta > span {
	font-family: var(--font), sans-serif;
	display: block;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .meta > span:first-child {
	font-family: var(--font-alt), sans-serif;
	font-weight: 600;
	color: var(--dark-text);
	font-size: 0.9rem;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .meta > span:nth-child(2), .account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .meta > span:nth-child(3) {
	font-size: 0.85rem;
	color: var(--light-text);
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .meta > span:nth-child(2) i, .account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .meta > span:nth-child(3) i {
	position: relative;
	top: -2px;
	font-size: 4px;
	margin: 0 6px;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .meta > span:nth-child(3) {
	color: var(--primary);
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .meta > span span {
	display: inline-block;
}
.account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .end {
	margin-left: auto;
}
.is-dark .account-wrapper .account-box {
	background: var(--dark-sidebar-light-6);
	border-color: var(--dark-sidebar-light-12);
}
.is-dark .account-wrapper .account-box .v-avatar img {
	border-color: var(--dark-sidebar-light-6) !important;
}
.is-dark .account-wrapper .account-box .avatar-stack .v-avatar {
	border-color: var(--dark-sidebar-light-6) !important;
}
.is-dark .account-wrapper .account-box .avatar-stack .v-avatar .avatar.is-more .inner {
	border-color: var(--dark-sidebar-light-6) !important;
}
.is-dark .account-wrapper .account-box.is-navigation .account-menu .account-menu-item.routerlinkexactactive {
	background: var(--dark-sidebar-light-8);
	border-color: var(--dark-sidebar-light-12);
}
.is-dark .account-wrapper .account-box.is-navigation .account-menu .account-menu-item.routerlinkexactactive i,
.is-dark .account-wrapper .account-box.is-navigation .account-menu .account-menu-item.routerlinkexactactive span {
	color: var(--primary);
}
.is-dark .account-wrapper .account-box.is-navigation .account-menu .account-menu-item:not(.routerlinkexactactive):hover {
	background: var(--dark-sidebar-light-10);
}
.is-dark .account-wrapper .account-box.is-navigation .account-menu .account-menu-item span {
	color: var(--dark-dark-text);
}
.is-dark .account-wrapper .account-box.is-form .form-head,
.is-dark .account-wrapper .account-box.is-form .form-foot {
	border-color: var(--dark-sidebar-light-12);
}
.is-dark .account-wrapper .account-box.is-form .form-head.is-stuck {
	background: var(--dark-sidebar);
	border-color: var(--dark-sidebar-light-6);
}
.is-dark .account-wrapper .account-box.is-form .form-head .left h3 {
	color: var(--dark-dark-text);
}
.is-dark .account-wrapper .account-box.is-form .form-body .fieldset .fieldset-heading h4 {
	color: var(--dark-dark-text);
}
.is-dark .account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item > img,
.is-dark .account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item > .icon-wrap,
.is-dark .account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item > .icon-wrap img {
	border-color: var(--dark-sidebar-light-12);
}
.is-dark .account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item > .icon-wrap {
	background: var(--dark-sidebar-light-2);
}
.is-dark .account-wrapper .account-box.is-form .form-body .fieldset .setting-list .setting-item .meta > span:nth-child(3) {
	color: var(--primary);
}	

.page-content-wrapper {
	width: 100%;
	margin: 0 auto;
}
.columns:not(.is-desktop) {
	display: flex;
}


.columns:last-child {
	margin-bottom: -0.75rem;
}

.columns {
	width: 100%;
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	margin-top: -0.75rem;
}
.column {
	display: block;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 0.75rem;
}

.column.is-4, .column.is-4-tablet {
	flex: none;
	width: 33.33333337%;
}
.account-wrapper .account-box.is-form .form-head .left p {
	font-size: 0.95rem;
}


.dropdown-item {
	width: 228px;
}

.button.is-primary:hover {
	opacity: 0.9;
	-webkit-box-shadow: var(--primary-box-shadow);
	box-shadow: var(--primary-box-shadow);
}

.button.v-button:not([disabled]) {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.button.v-button {
	padding: 8px 22px;
	height: 38px;
	line-height: 1.1;
	font-size: 0.95rem;
	font-family: "Roboto", sans-serif;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	white-space: nowrap;
	border-width: 1px;
}

.button.is-primary {
	background-color: #6c21f9;
	border-color: transparent;
	color: #ededed;
}

.button.is-fullwidth {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.button {
	-webkit-appearance: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid transparent;
	border-radius: 4px;
	-webkit-box-shadow: none;
	box-shadow: none;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 1rem;
	height: 2.5em;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	line-height: 1.5;
	padding-bottom: calc(0.5em - 1px);
	padding-left: calc(0.75em - 1px);
	padding-right: calc(0.75em - 1px);
	padding-top: calc(0.5em - 1px);
	position: relative;
	vertical-align: top;
}

button a {
	color: white;
}

.form-switch {
	position: relative;
	display: inline-block;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
  }
.form-switch:focus-within {
border-radius: 50px;
outline-offset: var(--accessibility-focus-outline-offset);
outline-width: var(--accessibility-focus-outline-width);
outline-style: var(--accessibility-focus-outline-style);
outline-color: var(--accessibility-focus-outline-color);
}
.form-switch.is-primary input:checked + i {
background-color: var(--primary);
}
.form-switch.is-success input:checked + i {
background-color: var(--success);
}
.form-switch.is-info input:checked + i {
background-color: var(--info);
}
.form-switch.is-warning input:checked + i {
background-color: var(--warning);
}
.form-switch.is-danger input:checked + i {
background-color: var(--danger);
}
.form-switch i {
position: relative;
display: inline-block;
width: 46px;
height: 26px;
background-color: #e6e6e6;
border-radius: 23px;
vertical-align: text-bottom;
transition: all 0.3s linear;
}
.form-switch i::before {
content: "";
position: absolute;
left: 0;
width: 42px;
height: 22px;
background-color: var(--white);
border-radius: 11px;
transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
transition: all 0.25s linear;
}
.form-switch i::after {
content: "";
position: absolute;
left: 0;
width: 22px;
height: 22px;
background-color: var(--white);
border-radius: 11px;
box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
transform: translate3d(2px, 2px, 0);
transition: all 0.2s ease-in-out;
}
.form-switch:active i::after {
width: 28px;
transform: translate3d(2px, 2px, 0);
}
.form-switch:active input:checked + i::after {
transform: translate3d(16px, 2px, 0);
}
.form-switch input {
position: absolute;
opacity: 0;
pointer-events: none;
}
.form-switch input:checked + i {
background-color: var(--light-text);
}
.form-switch input:checked + i::before {
transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}
.form-switch input:checked + i::after {
transform: translate3d(22px, 2px, 0);
}
.form-switch small {
color: var(--muted-grey);
position: relative;
top: -4px;
}
.switch-block {
padding: 10px 0;
display: flex;
align-items: center;
}
.switch-block .text {
margin-left: 6px;
}
.switch-block .text span {
display: block;
position: relative;
top: -2px;
color: var(--light-text);
}
.is-dark .form-switch.is-primary input:checked + i {
background-color: var(--primary) !important;
}
.is-dark .form-switch.is-primary input:checked + i::after {
background: var(--white) !important;
}
.is-dark .form-switch.is-success input:checked + i {
background-color: var(--success) !important;
}
.is-dark .form-switch.is-success input:checked + i::after {
background: var(--white) !important;
}
.is-dark .form-switch.is-info input:checked + i {
background-color: var(--info) !important;
}
.is-dark .form-switch.is-info input:checked + i::after {
background: var(--white) !important;
}
.is-dark .form-switch.is-warning input:checked + i {
background-color: var(--warning) !important;
}
.is-dark .form-switch.is-warning input:checked + i::after {
background: var(--white) !important;
}
.is-dark .form-switch.is-danger input:checked + i {
background-color: var(--danger) !important;
}
.is-dark .form-switch.is-danger input:checked + i::after {
background: var(--white) !important;
}
.is-dark .form-switch i {
background: var(--dark-sidebar) !important;
}
.is-dark .form-switch i::before {
background: var(--dark-sidebar) !important;
}
.is-dark .form-switch i::after {
background: var(--dark-sidebar-light-22) !important;
}
.is-dark .form-switch input:checked + i::after {
background: var(--dark-sidebar-light-55) !important;
}
.thin-switch {
display: block;
margin-left: 8px;
}
.thin-switch:focus-visible .slider::after {
border-radius: 50px;
outline-offset: var(--accessibility-focus-outline-offset);
outline-width: var(--accessibility-focus-outline-width);
outline-style: var(--accessibility-focus-outline-style);
outline-color: var(--accessibility-focus-outline-color);
}
.thin-switch:focus-visible {
outline: none !important;
}
.thin-switch.is-primary .input:checked ~ .slider {
background: var(--primary-light-20);
}
.thin-switch.is-primary .input:checked ~ .slider::after {
background: var(--primary);
border-color: var(--primary);
}
.thin-switch.is-success .input:checked ~ .slider {
background: var(--success-light-20);
}
.thin-switch.is-success .input:checked ~ .slider::after {
background: var(--success);
border-color: var(--success);
}
.thin-switch.is-info .input:checked ~ .slider {
background: var(--info-light-20);
}
.thin-switch.is-info .input:checked ~ .slider::after {
background: var(--info);
border-color: var(--info);
}
.thin-switch.is-warning .input:checked ~ .slider {
background: var(--warning-light-20);
}
.thin-switch.is-warning .input:checked ~ .slider::after {
background: var(--warning);
border-color: var(--warning);
}
.thin-switch.is-danger .input:checked ~ .slider {
background: var(--danger-light-20);
}
.thin-switch.is-danger .input:checked ~ .slider::after {
background: var(--danger);
border-color: var(--danger);
}
.thin-switch .slider {
position: relative;
display: inline-block;
height: 8px;
width: 32px;
border-radius: 8px;
cursor: pointer;
background: #c5c5c5;
transition: all 0.3s;
}
.thin-switch .slider::after {
background: var(--light-grey);
position: absolute;
left: -8px;
top: -8.5px;
display: block;
width: 24px;
height: 24px;
border-radius: var(--radius-rounded);
border: 1px solid transparent;
box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
content: "";
transition: all 0.3s;
}
.thin-switch label {
margin-right: 7px;
}
.thin-switch .input {
display: none;
}
.thin-switch .input ~ .label {
margin-left: 8px;
}
.thin-switch .input:checked ~ .slider::after {
left: 16px;
}
.thin-switch .input:checked ~ .slider::after {
background: var(--white);
border: 1px solid var(--fade-grey);
}
.thin-switch-block {
padding: 10px 0;
display: flex;
align-items: center;
}
.thin-switch-block .text {
margin-left: 16px;
}
.thin-switch-block .text span {
display: block;
position: relative;
color: var(--light-text);
}
.is-dark .thin-switch.is-primary .input:checked ~ .slider {
background: var(--primary-light-20);
}
.is-dark .thin-switch.is-primary .input:checked ~ .slider::after {
background: var(--primary);
border-color: var(--primary);
}
.is-dark .thin-switch.is-success .input:checked ~ .slider::after {
background: var(--success);
border-color: var(--success);
}
.is-dark .thin-switch.is-info .input:checked ~ .slider::after {
background: var(--info);
border-color: var(--info);
}
.is-dark .thin-switch.is-warning .input:checked ~ .slider::after {
background: var(--warning);
border-color: var(--warning);
}
.is-dark .thin-switch.is-danger .input:checked ~ .slider::after {
background: var(--danger);
border-color: var(--danger);
}
.is-dark .thin-switch .slider {
background: var(--dark-sidebar);
}
.is-dark .thin-switch .slider::after {
background: var(--dark-sidebar-light-22);
}
.is-dark .thin-switch .input:checked ~ .slider::after {
background: var(--dark-sidebar-light-55);
border: var(--dark-sidebar-light-55);
}

.account-box {
	margin-bottom: 50px;
}
.divider {
	height:26px;
}
:deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

:deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

:deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

:deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
    }
}

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
