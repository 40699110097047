<template>
	<transition name="pane-program">
		<div class="pane pane-program" id="gisementencours">
			<transition name="slide">
				<div v-if="mesnotes" style="position: absolute;top:0px;left:-400px;width:380px;height:600px;border: 1px solid #e7e9ed;background-color: white; border-radius:5px">
					<div style="width:100%; display:flex; justify-content: flex-start; padding:10px; border-bottom: 1px solid #e7e9ed;background-color: #f9fafb; border-top-right-radius:5px; border-top-left-radius:5px">
						<div style="width:18px;height:18px;border-radius: 9px;background-color:#c6d061"></div>
						<div>&nbsp;&nbsp;</div>
						<div>Statut de la fiche : à prospecter</div>
					</div>
					<div style="padding:10px;color: #b3b3b3">
						Ajouter un commentaire sur ce gisement
					</div>
				</div>
			</transition>
			
			<div style="height: 100%; width: 100%;box-sizing: content-box; padding-right: 10px;background-color: white; border: 1px solid rgb(204, 204, 204);border-radius:5px" id="GisementEx">
				<div id="header" style="height: 60px; width: 100%;">
					<div style="width:100%; height:48px; display:flex; justify-content: space-between; padding:10px">
						<div style="display:flex;height:48px;">
							<Button label="Mes notes" icon="pi pi-comment" class="p-button-rounded" style="margin-right:10px;" @click="mesnotes=!mesnotes"/>
							<div class="bu" ><i class="pi pi-info-circle"></i></div>
							<div class="bu"  @click="generateImage(expanded_gisement.gisement.id);"><i class="pi pi-download"></i></div>
							<div class="bu" ><i class="pi pi-star"></i></div>
							<div class="bu" ><i class="pi pi-share-alt"></i></div>
							<div style="vertical-align: middle; height: 100%; line-height: 48px;">{{ $t('Gisement foncier') }}<span v-if="expanded_gisement"> - {{ expanded_gisement.gisement.id }}</span></div>
						</div>
						<div style="height:48px;">
							<div class="bu"  @click="ferme();" ><i class="pi pi-times"></i></div>
						</div>
					</div>
					
				</div>
				<div id="content" class="content vb-content wrapper-scroll" style="display: block; overflow: hidden scroll; height: calc(100% - 120px); width: 100%;box-sizing: content-box; ">					
					<div style="width:100%; display:flex; justify-content: space-between; padding:10px">
						<div style="width:300px !important;">
							<div class="map_gisement" id="map_gisement"></div>
						</div>
						
						<div class="scoring-container" v-if="finalScoringHabitat">
							<div style="display:flex; justify-content: space-between;">
								<div style="width:160px">
									<div class="vocation">{{ finalScoringHabitat[0].libelle }}</div>
									<div style="width: 100%;text-align: center;display: flex;justify-content: center;">
										<div class="vocation_score" :class="{jaune: finalScoringHabitat[0].scoring<50}">{{finalScoringHabitat[0].scoring}}</div>
									</div>
									
									<div class="vocation">{{ finalScoringEco[0].libelle }}</div>
									<div style="width: 100%;text-align: center;display: flex;justify-content: center;">
										<div class="vocation_score" :class="{jaune: finalScoringEco[0].scoring<50}">{{finalScoringEco[0].scoring}}</div>
									</div>
									<div class="vocation">Surface du gisement</div>
									<div style="width: 100%;text-align: center;display: flex;justify-content: space-evenly;" v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.surfini">
										<!--<img src="../assets/img/area.png" alt="area" style="height:30px;width:30px;text-align:left;">-->
										<div style="vertical-align:middle;line-height: 40px;">{{ Math.round(expanded_gisement.gisement.surfini) }} m²</div>
									</div>

								</div>
								
								<div class="wrapper-scroll" style="display: block; overflow: hidden scroll;text-align: center;width:300px;height:250px;">
									<div v-if="indicateurSetPlus">
										<i class="pi pi-plus-circle" style="font-size: 2rem; color: green;margin-bottom:5px;"></i>
										<div style="display:flex; vertical-align: left;" v-for="(item, j) in indicateurSetPlus" :key="'indicateur_' + j + '_' + item.id" class="scoring-item">
											<div style="text-align: left;">{{ item.l }}</div>
										</div>
									</div>
									
									<div v-if="indicateurSetMoins">
										<i class="pi pi-minus-circle" style="font-size: 2rem; color: red;margin-bottom:5px;"></i>
										<div style="display:flex; vertical-align: middle;" v-for="(item, j) in indicateurSetMoins" :key="'indicateur_' + j + '_' + item.id" class="scoring-item">
											<div style="text-align: left;">{{ item.l }}</div>
										</div>
									</div>
									
								</div>
							</div>
							
							<!--
							<div class="scoring-final">							
								<ul class="accordion-menu">
									<li class="unmodule_detail_gistement" id="finalScoringHabitat" v-if="finalScoringHabitat">
										<div>
											<div class="icone_module_gisement"><icon file="real_estate_agent" style="color:#666;height:40px;width:40px;"/></div>
											<div style="left: 50px;top:15px">{{ finalScoringHabitat[0].libelle }}</div>
											<div class="note_gisement" :class="app.cityscan.adresse.note_transports">{{finalScoringHabitat[0].scoring}}</div>
											<div class="soustitre_module"></div>
										</div>
									</li>
									<div class="divider"></div>
									<li class="unmodule_detail_gistement" id="finalScoringEco" v-if="finalScoringEco">
										<div>
											<div class="icone_module_gisement"><icon file="factory" style="color:#666;height:40px;width:40px;"/></div>
											<div style="left: 50px;top:18px">{{ finalScoringEco[0].libelle }}</div>
											<div class="note_gisement" :class="app.cityscan.adresse.note_transports">{{finalScoringEco[0].scoring}}</div>
											<div class="soustitre_module"></div>
										</div>
									</li>	
								</ul>
							</div>
							
							<div class="scoring-indicateur">
								<div style="display:flex; vertical-align: middle;" v-for="(item, j) in indicateurSet" :key="'indicateur_' + j + '_' + item.id" class="scoring-item">
									<div style="padding-right:10px;"><div :class="item.c"><strong>{{ item.v }}</strong></div></div><div style="width: calc(100% - 30px)">{{ item.l }}</div>
								</div>
							</div>
							-->
						</div>	
					</div>
					<!--
					<div class="pan-titre-programme" id="pan-titre-gisement">
						<div class="module_ret">
							<div class="btns fermer" :title="$t('Fermer')"  @click="ferme();$store.dispatch('set_help_rs', {aide:false})"><icon file="fermer" :size="16" /></div>
							<div class="btns print" :title="$t('imprimer')" @click="generateImage(expanded_gisement.gisement.id);"><icon file="download" :size="24" />&nbsp;</div>
							<div class="btns chatbox" onclick="$('#zohohc-asap-web-launcherbox').click();"><icon file="question" :size="24" />&nbsp;</div>
							<div class="indice_confiance" :class="{ pc : true }">
								{{ $t('Gisement foncier') }}<span v-if="expanded_gisement"> - {{ expanded_gisement.gisement.id }}</span>
							</div>
						</div>
						<br/>
					</div>
					-->				
					<div style="display: flex;flex-direction: row;padding-left:10px;margin-bottom:10px">
						<InputSwitch v-model="vuedetaille" /> <div style="line-height: 28px;vertical-align: middle;">&nbsp;&nbsp;Vue détaillée des scores</div>
					</div>
					

					<div v-if="vuedetaille" style="padding:10px;">
						<div v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.scoring">
							<div v-for="sc in Tri(expanded_gisement.gisement.scoring)"  v-bind:key="sc.critere + '_' + sc.thematique + '_' + sc.libelle" style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-around;padding-bottom:6px">
								<div style="width:60%" :class="{bo: sc.c}">{{ sc.libelle }}</div>
								<div style="width:40%" :class="{bo: sc.c}">
									<div class="surface-300 w-full mt-2" style="height: 7px; border-radius: 4px">
										<div class="bg-teal-500" :class="{'bg-purple-500': sc.c}" style="height: 7px; border-radius: 4px" :style="{ width : sc.scoring + '%'}"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<div v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.parcelles" style="margin-top:10px;padding-left:15px">Parcelles : </div>
						
						<div v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.parcelles && expanded_gisement.gisement.parcelles.length < 6" class="p-carousel-items-container" style="padding-left:48px;justify-content: flex-start">
							<div class="p-carousel-item p-carousel-item-active p-carousel-item-start" v-for="parcelle in expanded_gisement.gisement.parcelles"  v-bind:key="parcelle.idpar">
								<div style="height: 60px; display: flex; align-items: center;margin-right:14px">
									<div class="filter first last" :class="{active: valueParcelle == parcelle.idpar}" @click="valueParcelle = parcelle.idpar">
										<h4 class="mb-1">{{parcelle.idpar}}</h4>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<Carousel v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.parcelles" :value="expanded_gisement.gisement.parcelles" :numVisible="5" :numScroll="4">
								<template #item="slotProps">
									<div style="height: 60px;display: flex;align-items: center;">
										<div class="filter first last" @click="valueParcelle = slotProps.data.idpar" :class="{active: valueParcelle == slotProps.data.idpar}">
											<h4 class="mb-1">{{slotProps.data.idpar}}</h4>
										</div>
									</div>
								</template>
							</Carousel>
						</div>
						<br>
						<div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: center;" v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.parcelles">
							<div class="program-detail-info">
								<div class="program-detail-info-filters">
									<span class="filter first" :class="{active: valueSelect == 'info'}" @click="valueSelect = 'info'">Informations Générales</span>
									<span class="filter" :class="{active: valueSelect == 'urbanisme'}" @click="valueSelect = 'urbanisme'">Urbanisme (3)</span>
									<span class="filter" :class="{active: valueSelect == 'risque'}" @click="valueSelect = 'risque'">Risques (3)</span>
									<span class="filter" :class="{active: valueSelect == 'transaction'}" @click="valueSelect = 'transaction'">Transactions</span>
									<span class="filter" :class="{active: valueSelect == 'batiment'}" @click="valueSelect = 'batiment'">Bâtiments (2)</span>
									<span class="filter last" :class="{active: valueSelect == 'proprietaire'}" @click="valueSelect = 'proprietaire'">Proprietaire <span v-if="activeparcelle_proprietaire && activeparcelle_proprietaire.length">({{ activeparcelle_proprietaire.length }})</span></span>
								</div>
							</div>
						</div>
						<br>
						<div v-if="app.active_gisement_loading">
							<div class="infinite-loader" style="text-align: center;">
								<div class="text">
									<p>{{$t('Chargement du gisement')}}</p>
									<p>{{ $t('en_cours') }} ...</p>
									<p>&nbsp;</p>
								</div>
								<div class="loader"><icon file="loader" :size="64" style="color:#333" /></div>
							</div>
						</div>
						<div v-else>
							<div class="program" :class="{ inactif: false }">
								<!--
								<div class="program-main" style="height:285px;">
									<a v-if="expanded_rpls.position && expanded_rpls.position.lat" target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_rpls.position.lat + ',' + expanded_rpls.position.lon">
										<div class="image">
											<img :src="expanded_rpls.urlphoto" alt="">
										</div>
									</a>
								</div>
								-->
							</div>
							<div v-if="activeparcelle" style="padding:10px">
								<div class="program-detail" v-if="valueSelect == 'info'" style="display:flex;">
									<div style="padding:10px; width:100%;border-radius:10px; border: 1px solid #eee; margin-bottom:10px;">
										<div style="margin-bottom:10px">Surface de la parcelle : <span v-if="activeparcelle.surface"><b>{{ Math.round(activeparcelle.surface) }} m²</b></span><span v-else> - </span></div>
										<div>Emprise au sol des bâtiments de la parcelle : <span v-if="activeparcelle.surface"><b>{{ Math.round(activeparcelle.surface * activeparcelle.ces) }} m²</b></span><span v-else> - </span></div>
									</div>
									<div>&nbsp;</div>
								</div>
								<div class="program-detail" v-if="valueSelect == 'urbanisme'" style="display:flex;">
									<div style="width:97%">
										<div style="padding:10px; width:100%;border-radius:10px; border: 1px solid #eee; margin-bottom:10px;">
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div style="margin-bottom:10px;" class="blur-text">Zone Ua</div>
												<div>Hauteur maximale de construction : {{ activeparcelle.bati_htmax }} m</div>
											</div>
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div>Prescriptions</div>
											</div>
										</div>
										<div style="padding:10px; width:100%;border-radius:10px; border: 1px solid #eee; margin-bottom:10px;">
											<Chip class="blur-text" style="margin-bottom:5px;margin-right:5px;" label="Zac des 3 chemins" />
										</div>
										<div style="padding:10px; width:100%;border-radius:10px; border: 1px solid #eee; margin-bottom:10px;">
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div class="blur-text" style="margin-bottom:10px;">Fiche potentielle</div>
												<div class="blur-text">Fiche d'Habitat</div>
											</div>
											<div style="padding-left:50px">
												<div style="margin-bottom:10px"><b>Nom : </b> <span  class="blur-text">Appel à projet Recyclage foncier 2021 ...</span></div>
												<div style="margin-bottom:10px"><b>Date d'identification : </b> <span  class="blur-text">2022</span></div>
												<div style="margin-bottom:10px"><b>Activité : </b> <span  class="blur-text">Garages, ateliers mécanique et soudure</span></div>
												<div style="margin-bottom:10px"><b>Etat du bâti : </b> <span  class="blur-text">Dégradation moyenne</span></div>
												<div style="margin-bottom:10px"><b>Surface de l'unité foncière : </b> <span  class="blur-text">11 793 m²</span></div>
												<div><b>Source producteur : </b> <span  class="blur-text">observatoire local</span></div>
											</div>
										</div>
									</div>
									<div>&nbsp;</div>
								</div>
								<div class="program-detail" v-if="valueSelect == 'risque'">
									<div style="display: flex;flex-direction: row;justify-content: flex-start;margin-bottom: 10px;">
										<Chip class="blur-text" style="margin-bottom:5px;margin-right:5px;background-color:#de4c47;color:white" label="à 5m d'un glissement de terrain" />
										<Chip class="blur-text" style="margin-bottom:5px;margin-right:5px;background-color:#de4c47;color:white" label="Gonflement d'argile" />
										<Chip class="blur-text" style="margin-bottom:5px;margin-right:5px;background-color:#f4c814;color:black" label="Zone innondable" />
									</div>
									<div style="display:flex;">
										<div style="width:97%">
											
											<div style="padding:10px; width:100%;border-radius:10px; border: 1px solid #eee; margin-bottom:10px;">
												<div style="display: flex;flex-direction: row;justify-content: space-between;">
													<div style="margin-bottom:10px;" class="blur-text">ANCIEN SITE INDUSTRIEL</div>
													<div class="blur-text">En activité</div>
												</div>
												<div style="padding-left:50px">
													<div style="margin-bottom:10px" class="blur-text">Station service Total</div>
													<div style="margin-bottom:10px" class="blur-text">Commerce de détail de carburants et lubrifiants</div>
												</div>
											</div>
											
											<div style="padding:10px; width:100%;border-radius:10px; border: 1px solid #eee; margin-bottom:10px;">
												<div style="display: flex;flex-direction: row;justify-content: space-between;">
													<div style="margin-bottom:10px;" class="blur-text">SOL POLLUE</div>
													<div></div>
												</div>
												<div style="padding-left:50px">
													<div style="margin-bottom:10px" class="blur-text">Sopral (ex-Dunlopillo)</div>
													<div style="margin-bottom:10px" class="blur-text">Statut d'instruction : En cours</div>
												</div>
											</div>
										</div>
										<div>&nbsp;</div>
									</div>
								</div>
								<div class="program-detail" v-if="valueSelect == 'transaction'" style="display:flex;">
									<pane-ancien-mutation
									v-for="mutation in activeparcelle.transactions.hits.hits"
									:mutation="mutation"
									class="bien hov"
									:id="'parcelle-' + mutation.id"
									v-bind:key="mutation.id"
									/>
								</div>
								<div class="program-detail" v-if="valueSelect == 'batiment'" style="display:flex;">
									<div style="width:97%">
										<div style="padding:10px; width:100%;border-radius:10px; border: 1px solid #eee; margin-bottom:10px;">
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div style="margin-bottom:10px;"><b>Usage</b>: <span class="blur-text">Tertiaire / Résidentiel / Commercial et Service</span></div>
											</div>
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div style="margin-bottom:10px;"><b>Hauteur</b>: <span class="blur-text">10m</span></div>
												<div><b>Année de construction</b>: <span class="blur-text">1950</span></div>
											</div>
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div style="margin-bottom:10px;"><b>Emprise au sol</b>: <span class="blur-text">500 m²</span></div>
												<div style="display: flex;flex-direction: row;justify-content: space-between;"><div><b>DPE</b>:&nbsp;</div><div class="blur-text" style="text-align:center;line-height:20px;vertical-align:middle;width:20px;height:20px;border-radius:5px;background-color: #f7b535;color: white">E</div></div>
												<div style="display: flex;flex-direction: row;justify-content: space-between;"><div><b>GES</b>:&nbsp;</div><div class="blur-text" style="text-align:center;line-height:20px;vertical-align:middle;width:20px;height:20px;border-radius:5px;background-color: #4e5070;color: white">E</div></div>
											</div>
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div><b>Nombre de logements</b>: <span class="blur-text">60 logts</span></div>
											</div>
										</div>
										
										<div style="padding:10px; width:100%;border-radius:10px; border: 1px solid #eee; margin-bottom:10px;">
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div style="margin-bottom:10px;"><b>Usage</b>: <span class="blur-text">Tertiaire / Résidentiel / Commercial et Service</span></div>
											</div>
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div style="margin-bottom:10px;"><b>Hauteur</b>: <span class="blur-text">15m</span></div>
												<div><b>Année de construction</b>: <span class="blur-text">1900</span></div>
											</div>
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div style="margin-bottom:10px;"><b>Emprise au sol</b>: <span class="blur-text">1 000 m²</span></div>
												<div style="display: flex;flex-direction: row;justify-content: space-between;"><div><b>DPE</b>:&nbsp;</div><div class="blur-text" style="text-align:center;line-height:20px;vertical-align:middle;width:20px;height:20px;border-radius:5px;background-color: #f58243;color: white">F</div></div>
												<div style="display: flex;flex-direction: row;justify-content: space-between;"><div><b>GES</b>:&nbsp;</div><div class="blur-text" style="text-align:center;line-height:20px;vertical-align:middle;width:20px;height:20px;border-radius:5px;background-color: #271c33;color: white">G</div></div>
											</div>
											<div style="display: flex;flex-direction: row;justify-content: space-between;">
												<div><b>Nombre de logements</b>: <span class="blur-text">10 logts</span></div>
											</div>
										</div>
										<div>&nbsp;</div>
									</div>
								</div>
								<div class="program-detail" v-if="valueSelect == 'proprietaire'" style="display:flex;flex-direction: column;">
									<div v-for="proprietaire in activeparcelle_proprietaire"  v-bind:key="proprietaire.idpk">
										<div style="width:97%">
											<div style="padding:10px; width:100%;border-radius:10px; border: 1px solid #eee; margin-bottom:10px;">
												<div style="margin-bottom:10px">{{$t('Forme')}} : <span> <b>{{ TraiteTexte(proprietaire.forme_juridique) }}</b></span></div>
												<div>{{$t('Dénomination')}} : <span><b>{{ proprietaire.denomination }}</b></span></div>
											</div>
										</div>
										<div>&nbsp;</div>
									</div>
								</div>
							</div>
							
							
							
							<!--
							<div class="program-detail">
								<div class="program-detail-content" style="padding-top: 8px !important;">
									<div class="program-detail-info">
										<ul class="list-none p-2 m-0">
											<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.surfini">
												<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-window-maximize mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Superficie')}} : {{ Math.round(expanded_gisement.gisement.surfini) }} m²</span>
												</div>
											</li>
											<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.zonage_urba">
												<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-map mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Zonage Urbain')}} : {{ expanded_gisement.gisement.zonage_urba }}</span>
												</div>
											</li>
											<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.gbgnb">
												<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-building mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Bâti / Non bâti')}} : {{ expanded_gisement.gisement.gbgnb }}</span>
												</div>
											</li>
											<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.env_patrimoine">
												<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-image mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Environnement patrimoine')}} : {{ expanded_gisement.gisement.env_patrimoine }}</span>
												</div>
											</li>
											<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.env_risque_nat">
												
												<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-bolt mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Risques naturels')}} : {{ expanded_gisement.env_risque_nat.gbgnb }}</span>
												</div>
											</li>
											<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.env_biodiversite">
												<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-star mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Biodiversité')}} : {{ expanded_gisement.gisement.env_biodiversite }}</span>
												</div>
											</li>
											<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.env_risque_indus">
												<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-server mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Risques industriels')}} : {{ expanded_gisement.gisement.env_risque_indus }}</span>
												</div>
											</li>
										</ul>
										<br>
									</div>
									<br>
									
									
									<div style="width:100%;display: flex;flex-direction: row;justify-content: center;">
										<SelectButton v-model="value1" :options="options" />
									</div>
									
									<div style="width:100%;padding-top:30px;" v-if="value1=='Parcelles'">
										<div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-around;" v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.parcelles">
											<div v-for="parcelle in expanded_gisement.gisement.parcelles"  v-bind:key="parcelle.idpar" class="parcelle">
												<div>{{$t('Parcelle N°')}} <b>{{ parcelle.idpar }}</b></div>
												<div>{{$t('Contenance')}} : <b>{{ parcelle.contenance }} {{$t('m²')}}</b></div>
												<div>{{ parcelle.pbpnb }}</div>
												<div>{{$t('Surface')}} : <b>{{ Math.round(parcelle.surface) }} {{$t('m²')}}</b></div>
												<div>{{$t('COS')}} : <b>{{ Math.round(parcelle.cos*10)/10 }}</b></div>
												<div>{{$t('CES')}} : <b>{{ Math.round(parcelle.ces*10)/10 }}</b></div>
												<div>{{$t('Hauteur max')}} : <b>{{ parcelle.bati_htmax }} {{$t('m')}}</b></div>
											</div>
										</div>
									</div>
									
									<div style="width:100%;padding-top:30px;" v-if="value1=='Propriétaires'">
										<div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-around;">
											<div v-for="proprietaire in expanded_proprietaire"  v-bind:key="proprietaire.idpk" class="parcelle" style="width:100%">
												<div>{{$t('Forme')}} <b>{{ proprietaire.forme_juridique }}</b></div>
												<div>{{$t('Dénomination')}} : <b>{{ proprietaire.denomination }}</b></div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
							-->
						</div>
					</div>
				</div>
				<div id="footer" style="height: 60px; width: 100%; border-top: 1px solid #CCC">
					<div style="display:flex;justify-content: center;height:38px;padding:10px; width:100%;border-radius:10px;margin-bottom:10px">
						<Button label="Estimer ma SDP" icon="" class="p-button-rounded" style="margin-right:10px;" @click="DialogMessage_Estimer=true" />
						<Button label="Simuler un projet résidentiel" icon="" class="p-button-rounded" style="margin-right:10px;" @click="DialogMessage_Simuler=true" />
						<Button label="Etude de concurrence" icon="" class="p-button-rounded" style="margin-right:10px;"  @click="DialogMessage_Etude=true"/>
					</div>
				</div>
			</div>

			<Dialog header="Estimer ma SDP " :visible.sync="DialogMessage_Estimer" :containerStyle="{width: '50vw'}">
				<p class="m-0"></p>
				<template #footer>
					<Button :label="$t('Fermer')" icon="pi pi-times" @click="closeBasicAncien" class="p-button-text"/>
				</template>
			</Dialog>

			<Dialog header="Simuler un projet résidentiel " :visible.sync="DialogMessage_Simuler" :containerStyle="{width: '50vw'}">
				<p class="m-0"></p>
				<template #footer>
					<Button :label="$t('Fermer')" icon="pi pi-times" @click="closeBasicAncien" class="p-button-text"/>
				</template>
			</Dialog>

			<Dialog header="Etude de concurrence " :visible.sync="DialogMessage_Etude" :containerStyle="{width: '50vw'}">
				<p class="m-0"></p>
				<template #footer>
					<Button :label="$t('Fermer')" icon="pi pi-times" @click="closeBasicAncien" class="p-button-text"/>
				</template>
			</Dialog>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line
import domtoimage from 'dom-to-image'
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import DataView from 'primevue/dataview';
import Panel from 'primevue/panel';
import SelectButton from 'primevue/selectbutton';
import Carousel from 'primevue/carousel';
import PaneAncienMutation from './pane-adresse/PaneAncienMutation.vue'
import Chip from 'primevue/chip';
import Dialog from 'primevue/dialog';
import InputSwitch from 'primevue/inputswitch';

export default {
	name: 'PaneGisement',

	data: function() {
		return {
			DialogMessage_Estimer: false,
			DialogMessage_Simuler: false,
			DialogMessage_Etude: false,
			vuedetaille: false,
			position: 'center',
			mesnotes: false,
			valueParcelle: null,
			valueSelect: 'info',
			tabTheme: ['vocation habitat', 'vocation économie'],
            value1: 'Parcelles',
            value2: null,
            value3: null,
            options: ['Parcelles', 'Propriétaires'],
			layout: 'grid',
			expanded: false,
			tva: 'tva_pleine_libre',
			pkg: 'prix_sp',
			filter: 'volume',
			filter2: 'base',
			suffixe: 'T',
			filter3: 'tva_pleine_libre_sans',
			filter4: 'global',
			filter_svo: 's',
			mode_aff: 'granulaire',
			mode_aff_histo: 'programme',
			compteur: 5,
			LayerImagery: false,
			map_gisement: false,
			layerGroup: false,
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		activeparcelle_proprietaire() {
			var t = []
			if (this.app.active_gisement) {
				for (let index = 0; index < this.app.active_gisement.length; index++) {
					if (this.app.active_gisement[index].idpar == this.valueParcelle) {
						if (this.app.active_gisement[index].proprietaires && this.app.active_gisement[index].proprietaires.length) {
							for (let i = 0; i < this.app.active_gisement[index].proprietaires.length; i++) {
								if (this.app.active_gisement[index].proprietaires[i].forme_juridique) t.push(this.app.active_gisement[index].proprietaires[i])
							}
						}
					}
				}
				return t
			} else return false
		},
		activeparcelle() {
			if (this.app.active_gisement) {
				for (let index = 0; index < this.app.active_gisement.length; index++) {
					if (this.app.active_gisement[index].idpar == this.valueParcelle) return this.app.active_gisement[index]
				}
				return false
			} else return false
		},

		finalScoringHabitat() {
			if (this.app.expanded_gisement && this.app.expanded_gisement.gisement && this.app.expanded_gisement.gisement.scoring) return this.app.expanded_gisement.gisement.scoring.filter((item) => item.scoring_type === "final" && item.thematique==="vocation habitat")
			else return null
		},
		finalScoringEco() {
			if (this.app.expanded_gisement && this.app.expanded_gisement.gisement && this.app.expanded_gisement.gisement.scoring) return this.app.expanded_gisement.gisement.scoring.filter((item) => item.scoring_type === "final" && item.thematique==="vocation économie")
			else return null
		},
		finalScoring() {
			if (this.app.expanded_gisement && this.app.expanded_gisement.gisement && this.app.expanded_gisement.gisement.scoring) return this.app.expanded_gisement.gisement.scoring.filter((item) => item.scoring_type === "final")
			else return null
		},

		indicateurSet() {
			var t = []
			if (this.app.expanded_gisement) {
				for (let index = 0; index < this.app.expanded_gisement.gisement.scoring.length; index++) {
					const score = this.app.expanded_gisement.gisement.scoring[index];
					if (score.libelle == "Périmètre de protection de la biodiversité" && score.thematique == 'vocation habitat' && score.scoring < 40 && score.scoring_type == "indicateur") {
						t.push({l:'Au moins une parcelle est située dans un périmètre de protection de la biodiversité', v: '-', c: 'moins'})
					}
					if (score.libelle == "Pollution des sols" && score.thematique == 'vocation habitat' && score.scoring == 0 && score.scoring_type == "indicateur") {
						t.push({l:'Au moins une parcelle est concernée par la pollution des sols', v: '-', c: 'moins'})
					}
					if (score.libelle == "Risque technologique" && score.thematique == 'vocation habitat' && score.scoring == 0 && score.scoring_type == "indicateur") {
						t.push({l:'Au moins une parcelle est concernée par un risque technologique', v: '-', c: 'moins'})
					}
					if (score.libelle == "Localisation en zonage U" && score.thematique == 'vocation habitat' && score.scoring == 0 && score.scoring_type == "indicateur") {
						t.push({l:'La parcelle n\'est pas dans une zone U du PLU', v: '-', c: 'moins'})
					}
					if (score.libelle == "Accès TC" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement est à moins de 100 m d\'un transport en commun', v: '+', c: 'plus'})
					}
					if (score.libelle == "Proximité des services - habitat" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement bénéficie d\'une offre de services environnants très supérieure à la moyenne', v: '+', c: 'plus'})
					}
					if (score.libelle == "Zonage compatible avec une activité économique" && score.thematique == 'vocation économie' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le zonage est compatible avec une implantation économique', v: '+', c: 'plus'})
					}
					if (score.libelle == "Occupation de la parcelle" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'La parcelle n\'est pas occupée, facilitant la mutation du foncier', v: '+', c: 'plus'})
					}
					if (score.libelle == "Superficie totale du potentiel" && score.thematique == 'vocation habitat' && score.scoring > 80 && score.scoring < 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement fait partie du Top 20% des plus grandes parcelles identifiées', v: '+', c: 'plus'})
					}
					if (score.libelle == "Superficie totale du potentiel" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement fait partie du top 10% des plus grandes parcelles identifiées', v: '+', c: 'plus'})
					}

					if (score.libelle == "Impact sur la consommation d'ENAF" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement n\'impacte pas, ou très peu, la consommation d\'espaces naturels, agricoles et forestiers (ENAF)', v: '+', c: 'plus'})
					}
					if (score.libelle == "zonages environnementales et risques" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "critere") {
						t.push({l:'La parcelle ne présente aucune contrainte liée à la protection de la biodiversité, à la pollution des sols, ou à un risque technologique', v: '+', c: 'plus'})
					}
					if (score.libelle == "vocation habitat" && score.thematique == 'vocation habitat' && score.scoring > 74 && score.scoring_type == "final") {
						t.push({l:'Top 10% : Le gisement présente une des meilleures notes pour la mutation foncière vers l\'habitat', v: '+', c: 'plus'})
					}
					if (score.libelle == "vocation économie" && score.thematique == 'vocation économie' && score.scoring > 69 && score.scoring_type == "final") {
						t.push({l:'Top 10% : Le gisement présente une des meilleures notes pour la mutation foncière à vocation économique', v: '+', c: 'plus'})
					}
					
				}
				t.sort((a, b) => {
					if (a.v < b.v) {
						return -1;
					}
					if (a.v > b.v) {
						return 1;
					}
					return 0;  // Si a.l et b.l sont égaux, il n'y a pas de changement
				});
				return t
			} else return null
		},


		indicateurSetPlus() {
			var t = []
			if (this.app.expanded_gisement) {
				for (let index = 0; index < this.app.expanded_gisement.gisement.scoring.length; index++) {
					const score = this.app.expanded_gisement.gisement.scoring[index];
					if (score.libelle == "Accès TC" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement est à moins de 100 m d\'un transport en commun', v: '+', c: 'plus'})
					}
					if (score.libelle == "Proximité des services - habitat" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement bénéficie d\'une offre de services environnants très supérieure à la moyenne', v: '+', c: 'plus'})
					}
					if (score.libelle == "Zonage compatible avec une activité économique" && score.thematique == 'vocation économie' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le zonage est compatible avec une implantation économique', v: '+', c: 'plus'})
					}
					if (score.libelle == "Occupation de la parcelle" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'La parcelle n\'est pas occupée, facilitant la mutation du foncier', v: '+', c: 'plus'})
					}
					if (score.libelle == "Superficie totale du potentiel" && score.thematique == 'vocation habitat' && score.scoring > 80 && score.scoring < 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement fait partie du Top 20% des plus grandes parcelles identifiées', v: '+', c: 'plus'})
					}
					if (score.libelle == "Superficie totale du potentiel" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement fait partie du top 10% des plus grandes parcelles identifiées', v: '+', c: 'plus'})
					}

					if (score.libelle == "Impact sur la consommation d'ENAF" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement n\'impacte pas, ou très peu, la consommation d\'espaces naturels, agricoles et forestiers (ENAF)', v: '+', c: 'plus'})
					}
					if (score.libelle == "zonages environnementales et risques" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "critere") {
						t.push({l:'La parcelle ne présente aucune contrainte liée à la protection de la biodiversité, à la pollution des sols, ou à un risque technologique', v: '+', c: 'plus'})
					}
					if (score.libelle == "vocation habitat" && score.thematique == 'vocation habitat' && score.scoring > 74 && score.scoring_type == "final") {
						t.push({l:'Top 10% : Le gisement présente une des meilleures notes pour la mutation foncière vers l\'habitat', v: '+', c: 'plus'})
					}
					if (score.libelle == "vocation économie" && score.thematique == 'vocation économie' && score.scoring > 69 && score.scoring_type == "final") {
						t.push({l:'Top 10% : Le gisement présente une des meilleures notes pour la mutation foncière à vocation économique', v: '+', c: 'plus'})
					}
					
				}
				t.sort((a, b) => {
					if (a.v < b.v) {
						return -1;
					}
					if (a.v > b.v) {
						return 1;
					}
					return 0;  // Si a.l et b.l sont égaux, il n'y a pas de changement
				});
				if (t.length) return t
				else return false
			} else return null
		},


		indicateurSetMoins() {
			var t = []
			if (this.app.expanded_gisement) {
				for (let index = 0; index < this.app.expanded_gisement.gisement.scoring.length; index++) {
					const score = this.app.expanded_gisement.gisement.scoring[index];
					if (score.libelle == "Périmètre de protection de la biodiversité" && score.thematique == 'vocation habitat' && score.scoring < 40 && score.scoring_type == "indicateur") {
						t.push({l:'Au moins une parcelle est située dans un périmètre de protection de la biodiversité', v: '-', c: 'moins'})
					}
					if (score.libelle == "Pollution des sols" && score.thematique == 'vocation habitat' && score.scoring == 0 && score.scoring_type == "indicateur") {
						t.push({l:'Au moins une parcelle est concernée par la pollution des sols', v: '-', c: 'moins'})
					}
					if (score.libelle == "Risque technologique" && score.thematique == 'vocation habitat' && score.scoring == 0 && score.scoring_type == "indicateur") {
						t.push({l:'Au moins une parcelle est concernée par un risque technologique', v: '-', c: 'moins'})
					}
					if (score.libelle == "Localisation en zonage U" && score.thematique == 'vocation habitat' && score.scoring == 0 && score.scoring_type == "indicateur") {
						t.push({l:'La parcelle n\'est pas dans une zone U du PLU', v: '-', c: 'moins'})
					}
					if (score.libelle == "Accès TC" && score.thematique == 'vocation habitat' && score.scoring == 100 && score.scoring_type == "indicateur") {
						t.push({l:'Le gisement est à moins de 100 m d\'un transport en commun', v: '+', c: 'plus'})
					}
				}
				t.sort((a, b) => {
					if (a.v < b.v) {
						return -1;
					}
					if (a.v > b.v) {
						return 1;
					}
					return 0;  // Si a.l et b.l sont égaux, il n'y a pas de changement
				});
				if (t.length) return t
				else return false
			} else return null
		},

		indicateurScoring() {
			if (this.app.expanded_gisement) return this.app.expanded_gisement.gisement.scoring.filter((item) => item.scoring_type === "indicateur");
			else return null
		},
		indicateurCritere() {
			if (this.app.expanded_gisement) return this.app.expanded_gisement.gisement.scoring.filter((item) => item.scoring_type === "critere");
			else return null
		},
		expanded_gisement: function() {
			return this.app.expanded_gisement;
		},
		expanded_proprietaire: function() {
			let t = []
			for (let index = 0; index < this.app.expanded_gisement.gisement.parcelles.length; index++) {
				for (let index2 = 0; index2 < this.app.expanded_gisement.gisement.parcelles[index].proprietaires.length; index2++) {

					let proprietaire = this.app.expanded_gisement.gisement.parcelles[index].proprietaires[index2];
					
					// Vérifiez si le propriétaire existe déjà dans le tableau t
					let existingIndex = t.findIndex(item => item.idpk === proprietaire.idpk || item.denomination === proprietaire.denomination);
					if (existingIndex === -1) {
						// Si le propriétaire n'existe pas, ajoutez-le au tableau
						proprietaire.parcelles = []
						proprietaire.parcelles.push(this.app.expanded_gisement.gisement.parcelles[index].idpar)
						t.push(proprietaire);
					} else {
						// Si le propriétaire existe, vous pouvez utiliser existingIndex pour faire quelque chose
						t[existingIndex].parcelles.push(this.app.expanded_gisement.gisement.parcelles[index].idpar)
					}
				}
			}
			return t;
		}
	}),

	watch: {
		'app.expanded_gisement': function(newValue) {
			this.mesnotes = false
			if (newValue) {
				if (newValue.gisement && newValue.gisement.parcelles) this.valueParcelle = newValue.gisement.parcelles[0].idpar
				this.majPolygone()
			} else {
				if (this.layerGroup) {
					this.layerGroup.clearLayers();
				}
			}
		}
	},
	
	methods: {
        closeBasicAncien: function() {
			this.DialogMessage_Estimer = false
			this.DialogMessage_Simuler = false
			this.DialogMessage_Etude = false
        },

		Tri: function(data) {
			let t = []
			let a = data.find(item => item.libelle === "pertinence habitat");
			if (a) {
				a.c = true
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Accès gare");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Accès TC");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Localisation en zonage compatible avec l'habitat");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Localisation en zonage U");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Proximité des services - habitat");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "pertinence économie tertiaire et présentielle");
			if (a) {
				a.c = true
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Desserte TC structurante");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Nombre d'emplois du bassin de vie");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Nombre d'habitants du bassin de vie");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Proximité des services - éco");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Zonage compatible avec une activité économique");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "consommation enaf");
			if (a) {
				a.c = true
				t.push(a)
			} 
			
			a = data.find(item => item.libelle === "Impact sur la consommation d'ENAF");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "mutabilite fonciere");
			if (a) {
				a.c = true
				t.push(a)
			} 
			
			a = data.find(item => item.libelle === "Domanialité / identité du propriétaire");
			if (a) {
				a.c = false
				t.push(a)
			} 
			
			a = data.find(item => item.libelle === "Occupation de la parcelle");
			if (a) {
				a.c = false
				t.push(a)
			} 
			
			a = data.find(item => item.libelle === "Patrimoine culturel");
			if (a) {
				a.c = false
				t.push(a)
			} 
			
			a = data.find(item => item.libelle === "Superficie totale du potentiel");
			if (a) {
				a.c = false
				t.push(a)
			}

			a = data.find(item => item.libelle === "zonages environnementales et risques" && item.thematique === "vocation habitat");
			if (a) {
				a.c = true
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Périmètre de protection de la biodiversité");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Pollution des sols");
			if (a) {
				a.c = false
				t.push(a)
			} 

			a = data.find(item => item.libelle === "Risque technologique");
			if (a) {
				a.c = false
				t.push(a)
			} 


			return t;

			/*			
			return data.slice().sort((a, b) => {
				// Trier par thematique
				if (a.thematique < b.thematique) return -1;
				if (a.thematique > b.thematique) return 1;

				// Si thematique est identique, trier par critere
				if (a.critere < b.critere) return -1;
				if (a.critere > b.critere) return 1;

				// Si critere est identique, trier par libelle
				if (a.libelle < b.libelle) return -1;
				if (a.libelle > b.libelle) return 1;

				// Si tout est identique, retournez 0
				return 0;
			});
			*/
		},
		TraiteTexte: function(text) {
			const replacements = {
				'и': 'è',
				'й': 'é',
				'к': 'ê',
				'Й': 'É',
				'д': 'd',
			};
			let t = text;
			try {
				t = t.replace(/[ийкЙд]/g, (char) => replacements[char] || char);
				t = t.replace(' а ', ' à ');
				t = t.charAt(0).toUpperCase() + t.slice(1);
			} catch (error) {
				console.log(text)
			}
			return t;
		},

		getFinaleNote: function(scoring_type, thematique) {
			for (let index = 0; index < this.app.expanded_gisement.gisement.scoring.length; index++) {
				const element = this.app.expanded_gisement.gisement.scoring[index];
				if (element.scoring_type == scoring_type && element.thematique == thematique) {
					return element
				}
			}
			return null
		},

		drawFeature: function(feature) {
			if (feature.geometry.type === "Polygon") {
				return path(feature);
			} else if (feature.geometry.type === "MultiPolygon") {
				return feature.geometry.coordinates.map(polygon => {
					return path({ type: "Polygon", coordinates: polygon });
				}).join(" ");
			}
		},

        // Fonction pour vider la div #map
        clearMap: function() {
            d3.select("#polygonContainer").select("svg").remove();
        },

		majPolygone: function() {
			if (!this.map_gisement) {
				this.ConstruitMap()
			}
			if (this.layerGroup) {
				this.layerGroup.clearLayers();
			} else {
				this.layerGroup = L.featureGroup().addTo(this.map_gisement)
			}
			for (let index = 0; index < this.app.gisements.features.length; index++) {
				const element = this.app.gisements.features[index]
				if (element.properties.gisement.id == this.app.expanded_gisement.gisement.id) {
					// Ajouter le GeoJSON à la carte
					L.geoJSON(element, {
						// eslint-disable-next-line
						style: function (feature) {
							return {
								color: '#ff7800',
								weight: 2,
								fillOpacity: 0.5
							};
						}
					}).addTo(this.layerGroup);
					this.map_gisement.fitBounds(this.layerGroup.getBounds());
				}
			}
/*
			// Extract coordinates from GeoJSON
			for (let index = 0; index < this.app.gisements.features.length; index++) {
				const element = this.app.gisements.features[index]
				if (element.properties.gisement.id == this.app.expanded_gisement.gisement.id) {

					const geojson = {
						"type": "FeatureCollection",
						"features": [
							element
						]
					};
					
					const width = 760;
					const height = 400;
					this.clearMap();

					const svg = d3.select("#polygonContainer")
						.append("svg")
						.attr("width", width)
						.attr("height", height);

					const projection = d3.geoIdentity().reflectY(true).fitSize([width, height], geojson);
					const path = d3.geoPath().projection(projection);

					svg.selectAll("path")
						.data(geojson.features)
						.enter().append("path")
						.attr("class", "polygon")
						.attr("d", path)
						.attr("fill", "#CCCCCC")
						.attr("stroke", "black");


					svg.selectAll("path")
						.data(geojson.features)
						.enter().append("path")
						.attr("class", "polygon")
						.attr("d", this.drawFeature)
						.attr("fill", "#CCCCCC")
						.attr("stroke", "black");

				}
			}
*/
			
		},

        selectContent(event) {
          // Sélectionner le contenu de l'input
          event.target.select();
        },

		isInteger: function(str) {
			const num = Number(str);
			return Number.isInteger(num);
		},

		generateImage(id) {
			const elementToExport = document.getElementById('GisementEx');
			let options = {
				height: elementToExport.scrollHeight,
				width: elementToExport.scrollWidth,
				style: {
					'transform': 'scale(1)',
					'transformOrigin': 'top left',
				},
			};

			domtoimage.toPng(elementToExport, options)
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.href = dataUrl;
				link.download = 'Gisement_' + id + '.png';
				link.click();
			})
			.catch((error) => {
				console.error('Erreur lors de la génération de l\'image', error);
			});

		},

		ferme: function() {
			this.$router.push({ name: 'gisement'}).catch(()=>{});
			this.$store.dispatch('expand_gisement', { selectgisement: false })
		},

		print: function() {
			this.$store.dispatch('print_program', { divname: "gisementencours"})
			this.$store.dispatch('SendEvent', { action: "gisement_download", opt_label: this.expanded_gisement.gisement.id, opt_value: 0 })
			this.$store.dispatch('set_print', { print: 'expanded-gisement' })
		},
		ConstruitMap: function() {
			let accessToken = 'pk.eyJ1Ijoic2ViZHVwb250IiwiYSI6ImNrdmdlcDMxaTAxdmUydXAzcW5yNDRpZmQifQ.8AvKt1ETauEmOWnK0TjNSg';

			this.LayerImagery = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken, {
					attribution: '© <a target="_blank" href="https://www.mapbox.com/feedback/">Mapbox</a> © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
					tileSize: 512,
					zoomOffset: -1
				});

			let la = this.LayerImagery

			this.map_gisement = L.map('map_gisement', {
				attribution: '<a target="_blank" href="https://leaflet.js/">Leaflet</a> ©',
				center: [46.32, 2.25],
				zoom: 6,
				minZoom: 12,
				zoomControl: true,
				editable: true,
				layers: [ la ]
			})
			L.control.scale().addTo(this.map_gisement);
			this.layerGroup = L.featureGroup().addTo(this.map_gisement)
		}
	},
	mounted: function() {
		/*
		if (!this.map_gisement) {
			this.ConstruitMap()
		}
		*/
	},
	// eslint-disable-next-line
	components: { domtoimage, Button, Accordion, AccordionTab, DataView, Panel, SelectButton, Carousel, PaneAncienMutation, Chip, Dialog, InputSwitch }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.pane-program-enter, .pane-program-leave-to
	transform translateX(100%)

.pan-titre-programme
	z-index 999
	position fixed
	float left
	with 100%
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%
.fermer
	position absolute
	width 60px
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.aide
	width 60px
	left 60px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.chatbox
	width 60px
	left 0px
	border-right 1px solid palette_gris_bordure
	position absolute
	text-align center
	line-height 60px
	cursor pointer
.print
	width 60px;
	position absolute
	right 60px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.module_ret
	width 800px
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.pane-program
	position absolute
	right 20px
	top calc(126px + 8px)
	bottom 0
	height calc(100% - 160px)
	width 800px
	background-color #fefefe
	transition 0.5s easeOutQuart
	z-index 599

.program
	width 100%
	border-bottom 2px solid #eee
	&.inactif
		.program-main
			background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		&:hover
			.program-main
				background lighten(#e3f2fd, 25%)
		.program-main
			background lighten(#e3f2fd, 50%)
			box-shadow inset -2px 2px 0 0 palette2, inset 2px 2px 0 0 palette2
			.program-content
				.name
					color palette2
		.program-detail
			box-shadow inset -2px -2px 0 0 palette2, inset 2px -2px 0 0 palette2
	&.	
		&:hover
			.program-main
				background lighten(orange, 85%)
		.program-main
			background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.program-main
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 75%
						background #eee
				.forme-urbaine
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		background #fefefe
		padding 16px
		.image
			position absolute
			left 200px
			top 16px
			height 285px
			width 396px
			border-radius radius
			background #eee url(../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 16px
			.name
				height 24px
				line-height 20px
				text-align center
			.city, .address
				color #999
				font-size 12px
			.seller
				margin 8px 0 0 0
				text-transform uppercase
				font-size 12px
				line-height 24px
			.forme-urbaine
				position absolute
				right 0
				bottom 0
				color #999
		.price
			position absolute
			left 44px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

.program-detail
	overflow hidden
	height auto
	&.detail-enter, &.detail-leave-to
		height 430px
		background-color red
	.program-detail-content
		padding 16px
		.resume
			margin 8px 0 0 0
			&:first-child
				margin 0
			&:after
				content ''
				display block
				clear both
			.resume-data
				float left
				width 33.3333%
				margin 0 0 8px 0
				.label
					font-size 10px
					text-transform uppercase
					color #999
				.value
					font-weight 500
		.actions
			margin 8px 0 0 0
			.action
				display inline-block
				margin 0 24px 0 0
				height 32px
				color #666
				font-size 12px
				font-weight 500
				line-height @height
				cursor pointer
				&:hover
					color #333
				.icon
					float left
					margin 4px 8px 0 0
		.program-detail-info
			padding 8px 0
			.program-detail-info-filters
				text-align center
				margin 0 0 8px 0
			.program-detail-info-content
				padding 8px 0
				background #f5f5f5
				border-radius radius
				overflow hidden
				table
					width 100%
					border-collapse collapse
					tr
						&:last-child
							border none
						&:hover
							td
						td, th
							padding 4px 8px
						th
							font-size 12px
							font-weight 500
						td
							text-align center
							&:first-child
								text-align left
								font-size 12px
								font-weight bold
								padding-left 16px

.actionaide
	position absolute
	right 140px
	top 0px
	z-index 999
.actionfermer
	position absolute
	right 22px
	top 0px
	z-index 999
.action_print
	position absolute
	right 60px
	top 0px
	z-index 999
.action_help
	position absolute
	right 100px
	top 0px
	z-index 999


.indice_confiance
	position absolute
	right 121px
	width 620px !important
	height 60px
	top 0px
	z-index 999
	vertical-align middle
	line-height 59px
	text-align center
	font-size 14px
	&.bas
		background qualitedonneesbas
	&.milieu
		background qualitedonneesmilieu
	&.haut
		background qualitedonneeshaut
	&.pc
		background #ccc

.vb
	.vb-dragger
		z-index 5
		width 10px
		right 0

.back-button
	cursor pointer
	border-radius 100%
	transition 0.25s easeOutQuart
	width 32px
	height 32px
	padding 4px	
	&:hover
		color #fff
		background-color darken(green, 10%)

.program-maj
	text-align center
	font-size 12px
	font-weight 500
	padding-left 16px
	color palette1

.nom
	color primaryA100

.cible
	color #000

.refreshprog
	height 185px
	width 750px

.infinite-loader
	position absolute
	right 307px
	top 28px
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

.Faible
	color #f2131f
	font-weight 600
.Moyen
	color #f2aa1f
	font-weight 600
.Fort
	color #1eaa1f
	font-weight 600
.recent
	color #1eaa1f
	font-weight 600

.center
	text-align center !important

.parcelle
	border-radius 8px
	background #EEEEEE
	margin-right 10px
	margin-bottom 10px
	padding 10px
	width 220px

#polygonContainer
	width 800px
	height 400px
	padding-right 20px
	padding-left 20px

.map_gisement_old
	height 400px
	width 760px
	margin-left 20px

.map_gisement
	height 250px
	width 300px !important
	margin-left 5px

.unmodule_detail_gistement
	height 60px
	width 335px
	padding-left 10px
	padding-top 10px
	vertical-align middle
	background-color #fff
	border-right 4px solid #fcfcfc

.note_gisement
	position absolute
	right 30px
	top 50%
	width 30px
	height 30px
	border-radius 75%
	text-align center
	vertical-align middle
	line-height 30px
	font-weight 500
	font-size 12pt

.icone_module_gisement
	font-size 1.875rem
	position absolute
	padding-top 4px

.vocation
	text-align center
	font-weight 600	
	margin-bottom 5px

.vocation_score
	width 48px
	height 48px
	border-radius 24px
	background-color #74b34d
	line-height 48px
	vertical-align middle
	text-align center
	font-size 18px
	color white
	font-weight 600
	margin-bottom 15px

.jaune
	background-color #ffd94e
	color black
.bo
	font-weight 600
	margin-top 20px

.scoring-container {
	display: flex;
	justify-content: space-between;
}

.scoring-final {
	flex: 0.6;
	padding: 10px;
}
.scoring-indicateur {
	flex: 2;
	padding: 10px;
}

.scoring-final {
	background-color: #f0f0f0;
}

.scoring-indicateur {
	background-color: #e0e0e0;
}

.scoring-item {
	margin-bottom: 10px;
	vertical-align: middle;
    align-items: center;
}

.plus {
	border-radius: 50%;
	background-color: #3a9731;
	color: white;
	width: 20px;
	height: 20px;
	text-align: center;
    vertical-align: middle;
    line-height: 20px;	
}

.moins {
	border-radius: 50%;
	background-color: red;
	color: white;
	width: 20px;
	height: 20px;
	text-align: center;
    vertical-align: middle;
    line-height: 20px;
}


.bu {
	margin-right: 10px;
    width: 48px !important;
    height: 48px !important;
    color: #6b23f1;
    text-align: center;
    vertical-align: middle;
    line-height: 48px;
    border-radius: 50%;
}
.bu:hover {
	background-color: #eee;
}

/* Transition animations */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter {
  transform: translateX(400px); /* Commence à droite */
}

.slide-leave-to {
  transform: translateX(400px); /* Glisse vers la droite pour disparaître */
}

.blur-text {
    filter: blur(5px); /* Applique un flou de 5 pixels */
}


@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none


</style>
