import Vue from 'vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'

// STATE
const state = {
	BaseUrlBackend: process.env.VUE_APP_API_URL,
	logged				: false,
	user_menu			: false,
	historique_efocus	: [],
	initial				: "",
	NomPrenom			: "",
	Mail				: "",
	nolive				: false,
	module_cityscan		: false,
	module_foncier		: false,
	module_rs			: false,
	territoires_module	: [],
	territoires			: [],
	territoires_rs		: [],
	lib_ter_rss			: "",
	lib_ter_pro			: "",
	lib_ter_mod			: "",
	rs					: false,
	groupe				: "",
	filiale				: "",
	logo				: false
}


// GETTERS
const getters = {
	user: function(state) { return state }
}



// ACTIONS
const actions = {

	show_user_menu: function({ commit }, data) {
		commit('SET_USER_MENU', { user_menu: data.visible })
	},
	

	getAutorisation: function({ dispatch }, data) {
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + data.token
		}
		
		axios.post(process.env.VUE_APP_API_URL + '/user/autorisation', data, {headers: headers})
			.then(function(response) {
				if (response.status == '200') {
					if (response.data) {
						dispatch('set_terrtoires_neuf', { territoires_neuf: response.data })
					}
				}
			}) 
			.catch(function() {
				dispatch('set_loading', { loading: false })
			});
	},

	loginbytoken: function({ commit, dispatch }, data) {
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + state.jwt
		}
		VueCookies.remove('EFOCUSPPFLOG')
		dispatch('set_loading', { loading: true })
		try {
			axios.post(process.env.VUE_APP_API_URL + '/user/loginbytoken', data, {headers: headers})
			.then(function(response) {
				if (response.status == '200') {
					if (response.data) {
						if (response.data) {
							if (response.data.idespaceclient == '') {
								dispatch('add_notification', { status: 'error', message: response.data.messages })
								dispatch('SendEvent', { action: "account_connect_error", opt_label: "connexion error", opt_value: 0 })
								dispatch('send_event_backend', {module: 'connexion', action: 'login by token echec', option: data});
							} else  {
								dispatch('set_jwt', { jwt: response.data.token, user_id:  response.data.idespaceclient})
								localStorage.setItem("user", response.data.token)
								VueCookies.set('jwt' , response.data.token)
								dispatch('send_event_backend', {module: 'connexion', action: 'login by token success', option: data});
								dispatch('set_user', { user: response.data })
								dispatch('set_nolive', { nolive: response.data.nolive })
								dispatch('set_module_cityscan', { module_cityscan: response.data.cityscan })
								dispatch('set_module_foncier', { module_foncier: response.data.foncier })
								dispatch('set_module_rs', { module_rs: response.data.rs })
								dispatch('SendEvent', { action: "account_connect_success", opt_label: "connexion succes", opt_value: 1 })
								if (response.data.CGU) {
								for (let index = 0; index < response.data.CGU.length; index++) {
									dispatch('add_nouveaute', { url: response.data.CGU[index] })
									}
								}
								dispatch('set_territoires_module', { territoires_module: response.data.territoires_module })
								// Gestion préférences
								//if (response.data.preferences) dispatch('set_preferences', { preferences: response.data.preferences, init : true })

								commit('EDIT_USER', { logged: true, initial: response.data.prenom[0] + response.data.nom[0], NomPrenom: response.data.nom + ' ' + response.data.prenom, Mail: response.data.email, module_cityscan: response.data.cityscan, nolive: response.data.nolive, territoires:response.data.Territoire, logo:response.data.logo, territoires_module:response.data.territoires_module, rs: response.data.rs, territoires_rs:response.data.territoires_rs, lib_ter_rss:response.data.lib_ter_rss, lib_ter_pro:response.data.lib_ter_pro, lib_ter_mod:response.data.lib_ter_mod, groupe: response.data.groupe, filiale: response.data.filiale})
								dispatch('set_loading', { loading: false })
								dispatch('getAutorisation', {token : response.data.token})
							}
						}
					}
					if (data.callback) data.callback()
				}
			}) 
			.catch(function() {
				dispatch('set_loading', { loading: false })
			})
			.then(function() {
				if (data && data.callback) data.callback()
			})
		} catch (error) {
			dispatch('set_loading', { loading: false })
		}
		dispatch('gethistoefocus', {})
	},

	login: function({ commit, dispatch }, data) {
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + state.jwt
		}
		dispatch('set_loading', { loading: true })
		VueCookies.remove('EFOCUSPPFLOG')
		data.app = 'efocus'
		axios.post(process.env.VUE_APP_API_URL + '/user/login', data, {headers: headers})
			.then(function(response) {
				if (response.status == 200) {
					if (response.data) {
						if (response.data.idespaceclient == '') {
							dispatch('send_event_backend', {module: 'connexion', action: 'login echec', option: data});
							dispatch('add_notification', { status: 'error', message: response.data.messages })
							dispatch('SendEvent', { action: "account_connect_error", opt_label: "connexion error", opt_value: 0 })
						} else  {
								dispatch('set_jwt', { jwt: response.data.token, user_id:  response.data.idespaceclient})
								localStorage.setItem("user", response.data.token)
								VueCookies.set('jwt' , response.data.token)
							dispatch('send_event_backend', {module: 'connexion', action: 'login success', option: data});
							dispatch('set_user', { user: response.data })
							dispatch('set_nolive', { nolive: response.data.nolive })
							dispatch('set_module_cityscan', { module_cityscan: response.data.cityscan })
							dispatch('set_module_foncier', { module_foncier: response.data.foncier })
							dispatch('set_module_rs', { module_rs: response.data.rs })
							dispatch('SendEvent', { action: "account_connect_success", opt_label: "connexion succes", opt_value: 1 })
							if (response.data.CGU) {
								for (let index = 0; index < response.data.CGU.length; index++) {
									dispatch('add_nouveaute', { url: response.data.CGU[index] })
								}
							}
							dispatch('getAutorisation', {token : response.data.token})
							dispatch('set_territoires_module', { territoires_module: response.data.territoires_module })
							// Gestion préférences
							//if (response.data.preferences) dispatch('set_preferences', { preferences: response.data.preferences, init : true })

							commit('EDIT_USER', { logged: true, initial: response.data.prenom[0] + response.data.nom[0], NomPrenom: response.data.nom + ' ' + response.data.prenom, Mail: response.data.email, module_cityscan: response.data.cityscan, nolive: response.data.nolive, territoires:response.data.Territoire, logo:response.data.logo, territoires_module:response.data.territoires_module, lib_ter_rss:response.data.lib_ter_rss, lib_ter_pro:response.data.lib_ter_pro, lib_ter_mod:response.data.lib_ter_mod, groupe: response.data.groupe, filiale: response.data.filiale})
							dispatch('set_loading', { loading: false })
						}
					}
					if (data.callback) data.callback()
				} else {
					dispatch('set_loading', { loading: false })
					dispatch('send_event_backend', {module: 'connexion', action: 'login echec', option: data});
					dispatch('add_notification', { status: 'error', message: 'Vérifier votre login et mot de passe' })
					dispatch('SendEvent', { action: "account_connect_error", opt_label: "connexion error", opt_value: 0 })
				}
			}) 
			.catch(function(error) {
				console.log(error)
				dispatch('set_loading', { loading: false })
				dispatch('send_event_backend', {module: 'connexion', action: 'login echec', option: data});
				dispatch('add_notification', { status: 'error', message: 'Vérifier votre login et mot de passe' })
				dispatch('SendEvent', { action: "account_connect_error", opt_label: "connexion error", opt_value: 0 })
			})
			.then(function() {
				if (data && data.callback) data.callback()
			})
			
		dispatch('gethistoefocus', {})
	},

	logout: async function() {
	},
		
	signout: function({ commit }) {
		localStorage.removeItem('jwt')
		localStorage.removeItem('user')
		VueCookies.remove('EFOCUSPPFLOG')
		commit('EDIT_USER', { logged: false })
		commit('SET_SIGNOUT')
	},
	
	gethistoefocus: function({ commit }) {
		commit('RAZ_HISTO_EFOCUS', {  })
		}

}



// MUTATIONS
const mutations = {

	EDIT_USER: function(state, data) {
		for (let i in data) {
			Vue.set(state, i, data[i])	
		}
	},

	SET_USER_MENU: function(state, data) {
		state.user_menu = data.user_menu
	},
	
	RAZ_HISTO_EFOCUS: function(state) {
		state.historique_efocus = []
	},
	
	ADD_HISTO_EFOCUS: function(state, data) {
		for (let i in data.data) {
			state.historique_efocus.push(data.data[i])		
		}
	},

	
}

export default { state, getters, actions, mutations, VueCookies }